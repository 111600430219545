import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AssignInjection from "../../../components/AssignInjection";
import { INIT_ASSIGN_VACCINE, INIT_ASSIGN_VACCINE_ERROR } from "../../../constants/vaccines";
import { getAge } from "../../../utils/date";
import apis from "../../../apis";
import { handleCallApiError } from "../../../errors";

import AdultExamination from "./AdultExamination";
import ChildExamination from "./ChildExamination";
import AdultExaminationSheet from "./AdultExaminationSheet";
import ChildExaminationSheet from "./ChildExaminationSheet";
import { CHECK_LIST_STATUS } from "../../../constants/checkList";

const INITIAL_CHILD_RESULT_EXAMINATION = {
    weight: "",
    height: "",
    hypothermia: "36.8",
    bloodPressure: "",
    heartbeat: "",
    survey: [0, 0, 0, 0, 0, 0, 0, 0],
    conclusion: 1,
    notes: [0, 0],
};

const INITIAL_ADULT_RESULT_EXAMINATION = {
    hypothermia: "36.8",
    bloodPressure: "",
    heartbeat: "",
    breathing: "",
    survey: [0, 0, 0, 0, 0, 0, 0, 0],
    conclusion: 1,
    notes: [0, 0, 0, 0],
};

const Examination = () => {
    const componentRef = useRef();
    const [assigned, setAssigned] = useState(true);
    const [assignVaccines, setAssignVaccines] = useState([]);
    const [assignVaccinesError, setAssignVaccinesError] = useState([]);
    const [examination, setExamination] = useState({});
    const [canEdit, setCanEdit] = useState(true);
    const [age, setAge] = useState(0);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState("");
    const [saving, setSaving] = useState(false);
    const [savingAndPrinting, setSavingAndPrinting] = useState(false);

    const checkList = useSelector((state) => state.checkList);

    const handleChangeNote = (event) => setNote(event.target.value);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Ket_qua_kham_sang_loc_${checkList.id}`,
    });

    const validateResult = () => {
        let isSuccess = true;
        let vaccinesError = [...assignVaccinesError];
        vaccinesError = vaccinesError.map((vaccineError, index) => {
            let error = { ...vaccineError };

            if (assignVaccines[index].vaccineId === null) {
                isSuccess = false;
                error = { ...error, vaccineId: true };
            }

            if (assignVaccines[index].dosage === "none") {
                isSuccess = false;
                error = { ...error, dosage: true };
            }
            return error;
        });
        setAssignVaccinesError(vaccinesError);
        return isSuccess;
    };

    const handleSave = async () => {
        if (!validateResult()) {
            toast.error("Vui lòng điền đầy đủ thông tin vắc-xin chỉ định");
            return;
        }
        setSaving(true);
        try {
            await apis.checkList.saveExaminationSheet({
                checkListId: checkList.id,
                examinationResult: examination,
                vaccines: assignVaccines,
                note,
            });
            setAssigned(true);
            setCanEdit(false);
            toast.success("Lưu phiếu thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setSaving(false);
    };

    const handleSaveAndPrint = async () => {
        if (!validateResult()) {
            toast.error("Vui lòng điền đầy đủ thông tin vắc-xin chỉ định");
            return;
        }
        setSavingAndPrinting(true);
        try {
            await apis.checkList.saveExaminationSheet({
                checkListId: checkList.id,
                examinationResult: examination,
                vaccines: assignVaccines,
                note,
            });
            setAssigned(true);
            setCanEdit(false);
            handlePrint();
            toast.success("Lưu phiếu thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setSavingAndPrinting(false);
    };

    const transformVaccineData = (vaccines) => {
        return vaccines.map((vaccine) => ({
            vaccineId: vaccine.vaccineId,
            numberOfInjections: vaccine.numberOfInjections,
            dosage: vaccine.dosage,
            discount: vaccine.discount,
        }));
    };

    const initAssignVaccinesError = (count) => {
        let errors = [];
        for (let index = 0; index < count; index++) {
            errors.push(INIT_ASSIGN_VACCINE_ERROR);
        }
        return errors;
    };

    const handleChangeCanEdit = () => {
        setCanEdit((prev) => !prev);
    };

    const renderAction = () => {
        if (!assigned)
            return (
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSave}
                        loading={saving}
                        disabled={savingAndPrinting}
                    >
                        Lưu chỉ định
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        color="success"
                        onClick={handleSaveAndPrint}
                        loading={savingAndPrinting}
                        disabled={saving}
                    >
                        Lưu &amp; In phiếu
                    </LoadingButton>
                </Stack>
            );

        if (canEdit)
            return (
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSave}
                        loading={saving}
                        disabled={savingAndPrinting}
                    >
                        Lưu cập nhật
                    </LoadingButton>
                    <LoadingButton
                        variant="outlined"
                        color="success"
                        onClick={handleSaveAndPrint}
                        loading={savingAndPrinting}
                        disabled={saving}
                    >
                        Lưu &amp; In phiếu
                    </LoadingButton>
                    <Button variant="outlined" onClick={handleChangeCanEdit}>
                        Hủy
                    </Button>
                </Stack>
            );

        return (
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Button variant="contained" onClick={handleChangeCanEdit} color="warning">
                    Chỉnh sửa
                </Button>
                <Button variant="contained" onClick={handlePrint} color="success">
                    In phiếu
                </Button>
            </Stack>
        );
    };

    const fetchAssignVaccines = async (checkListId) => {
        try {
            const { assignVaccines: vaccineData } = await apis.checkListVaccine.getAssignVaccines(checkListId);
            if (vaccineData.length) {
                setAssignVaccines(transformVaccineData(vaccineData));
                setAssignVaccinesError(initAssignVaccinesError(vaccineData.length));
                setAssigned(true);
                setCanEdit(false);
            } else {
                setAssignVaccines([INIT_ASSIGN_VACCINE, INIT_ASSIGN_VACCINE]);
                setAssignVaccinesError([INIT_ASSIGN_VACCINE_ERROR, INIT_ASSIGN_VACCINE_ERROR]);
            }
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const convertExamination = (content) => {
        const newContent = { ...content };
        Object.keys(content).forEach((key) => {
            if (!newContent[key]) {
                newContent[key] = "";
            }
        });
        return newContent;
    };

    const init = async (checkList) => {
        setLoading(true);
        await fetchAssignVaccines(checkList?.id);
        const customerAge = getAge(checkList?.customer?.dateOfBirth, checkList?.createdAt);
        setAge(customerAge);
        if (checkList?.examinationContent) {
            setExamination(convertExamination(JSON.parse(checkList?.examinationContent)));
        } else {
            customerAge > 15
                ? setExamination(INITIAL_ADULT_RESULT_EXAMINATION)
                : setExamination(INITIAL_CHILD_RESULT_EXAMINATION);
        }

        if (checkList?.status === CHECK_LIST_STATUS.WAITING_FOR_EXAMINATION) {
            setAssigned(false);
            setCanEdit(true);
        }
        setNote(checkList?.note ? checkList?.note : "");
        setLoading(false);
    };

    useEffect(() => {
        init(checkList);
    }, [checkList]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            {Object.keys(examination).length > 0 && (
                <Box mb={3}>
                    {age > 15 ? (
                        <AdultExamination examination={examination} setExamination={setExamination} canEdit={canEdit} />
                    ) : (
                        <ChildExamination examination={examination} setExamination={setExamination} canEdit={canEdit} />
                    )}
                </Box>
            )}
            <Box mb={2}>
                <AssignInjection
                    assignVaccines={assignVaccines}
                    setAssignVaccines={setAssignVaccines}
                    assignVaccinesError={assignVaccinesError}
                    setAssignVaccinesError={setAssignVaccinesError}
                    canEdit={canEdit}
                />
            </Box>
            <Box mb={2}></Box>
            <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                    Ghi chú:
                </Typography>
                <TextField
                    value={note}
                    size="small"
                    fullWidth
                    multiline={true}
                    onChange={handleChangeNote}
                    rows={3}
                    disabled={!canEdit}
                />
            </Box>
            {renderAction()}
            {Object.keys(examination).length > 0 && (
                <div style={{ display: "none" }}>
                    {age > 15 ? (
                        <AdultExaminationSheet ref={componentRef} examination={examination} vaccines={assignVaccines} />
                    ) : (
                        <ChildExaminationSheet ref={componentRef} examination={examination} vaccines={assignVaccines} />
                    )}
                </div>
            )}
        </>
    );
};

export default Examination;
