import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Delete, Edit, HighlightOff, Visibility } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../../components/Table";
import apis from "../../../apis";
import debounce from "../../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../../constants";
import { CHECK_LIST_STATUS, checkListStatus, CHECK_LIST_STATUS_DEFAULT } from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import { handleCallApiError } from "../../../errors";
import DeleteCheckListDialog from "../../DeleteCheckListDialog";
import ReceiptSheet from "../Cashier/ReceiptSheet";
import { useReactToPrint } from "react-to-print";
import OrderDialog from "./OrderDialog";
import CancelPaymentDialog from "../Cashier/CancelPaymentDialog";
import ReactDialog from "./ReactDialog";

const RegisteredList = ({ search, status, paging, onChangePage }) => {
    const [checkLists, setCheckLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [orderDialog, setOrderDialog] = useState(false);
    const [checkList, setCheckList] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [paymentType, setPaymentType] = useState(null);
    const [cancelDialog, setCancelDialog] = useState(false);
    const [reactDialog, setReactDialog] = useState(false);
    const [reaction, setReaction] = useState("");
    const currentStore = useSelector((state) => state.store.currentStore);
    const componentRef = useRef();

    const fetchCheckLists = async () => {
        setLoading(true);
        try {
            const condition = {
                search,
                status: status === CHECK_LIST_STATUS_DEFAULT ? null : status,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCheckLists(condition);
            setCheckLists(data?.checkLists || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleChangeConfirmDeleteDialog = (row) => {
        setCheckList(row);
        setConfirmDeleteDialog((prev) => !prev);
    };

    const handleDelete = async () => {
        setWaiting(true);
        try {
            await apis.checkList.deleteCheckList(checkList.id);
            const customerName = checkList.customer.name;
            setCheckList({});
            setConfirmDeleteDialog(false);
            fetchCheckLists();
            toast.success(`Phiếu đăng ký của ${customerName} đã bị xóa`);
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handlePrintOrder = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `hoa_don_thanh_toan_${checkList.id}`,
        onAfterPrint: () => {
            handleCloseOrderDialog();
        },
    });

    const handleOpenOrderDialog = (row) => {
        setCheckList(row);
        setPaymentType(row.paymentType);
        setOrderDialog(true);
    };

    const handleCloseOrderDialog = () => {
        setCheckList({});
        setOrderDialog(false);
    };

    const handleOpenCancelDialog = (row) => {
        setCheckList(row);
        setCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setCheckList({});
        setCancelDialog(false);
    };

    const handleCancelPayment = async () => {
        setWaiting(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, { status: CHECK_LIST_STATUS.CANCEL });
            handleCloseCancelDialog();
            toast.success("Hủy thanh toán thành công");
            fetchCheckLists();
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleOpenReactDialog = (row) => {
        setCheckList(row);
        if (row.reaction) setReaction(row.reaction);
        setReactDialog(true);
    };

    const handleCloseReactDialog = () => {
        setCheckList({});
        setReaction("");
        setReactDialog(false);
    };

    const handleUpdateReaction = async () => {
        setWaiting(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, { status: CHECK_LIST_STATUS.DONE, reaction });
            handleCloseReactDialog();
            fetchCheckLists();
            toast.success(`Cập nhật phản ứng sau tiêm thành công`);
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const renderStatus = (status) => {
        const statusObject = checkListStatus.find((s) => s.value === status);
        if (!statusObject) return;
        switch (statusObject.value) {
            case CHECK_LIST_STATUS.DONE:
                return (
                    <Typography color="primary" fontSize="13px">
                        {statusObject.label}
                    </Typography>
                );
            case CHECK_LIST_STATUS.CANCEL:
                return (
                    <Typography sx={{ color: COLOR.grey }} fontSize="13px">
                        {statusObject.label}
                    </Typography>
                );
            default:
                return (
                    <Typography sx={{ color: COLOR.red }} fontSize="13px">
                        {statusObject.label}
                    </Typography>
                );
        }
    };

    const renderAction = (row) => {
        switch (row.status) {
            case CHECK_LIST_STATUS.WAITING_FOR_EXAMINATION:
                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Xóa phiếu">
                            <IconButton onClick={() => handleChangeConfirmDeleteDialog(row)}>
                                <Delete sx={{ color: COLOR.grey }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            case CHECK_LIST_STATUS.WAITING_FOR_INJECTION:
                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Xem hóa đơn">
                            <IconButton onClick={() => handleOpenOrderDialog(row)}>
                                <Visibility sx={{ color: COLOR.blue }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Hủy thanh toán">
                            <IconButton onClick={() => handleOpenCancelDialog(row)}>
                                <HighlightOff sx={{ color: COLOR.danger }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            case CHECK_LIST_STATUS.WAITING_FOR_REACTION:
                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Cập nhật">
                            <IconButton onClick={() => handleOpenReactDialog(row)}>
                                <Edit sx={{ color: COLOR.purple }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            case CHECK_LIST_STATUS.DONE:
                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Cập nhật">
                            <IconButton onClick={() => handleOpenReactDialog(row)}>
                                <Edit sx={{ color: COLOR.purple }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );

            default:
                break;
        }
    };

    const columns = [
        {
            field: "status",
            title: "Trạng thái",
            align: "left",
            render: (row) => renderStatus(row.status),
        },
        {
            field: "order",
            title: "Số phiếu",
            align: "center",
        },
        {
            title: "Thời gian đăng ký",
            align: "center",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <>
                    <Typography color="primary" fontSize="13px">
                        {row.customer.name}
                    </Typography>
                    <Typography fontSize="13px">{row.customer.id}</Typography>
                </>
            ),
        },
        {
            title: "Ngày sinh",
            align: "left",
            render: (row) => row.customer.dateOfBirth,
        },
        {
            title: "Vắc xin chỉ định",
            align: "left",
            render: (row) => (
                <>
                    {row.vaccines.map((vaccine, index) => (
                        <Typography fontSize="13px">{`${index + 1}. ${vaccine.name} | ${vaccine.desc}`}</Typography>
                    ))}
                </>
            ),
        },
        {
            field: "note",
            title: "Ghi chú",
            align: "left",
        },
        {
            field: "update_last_time_by",
            title: "Người thao tác cuối",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) => renderAction(row),
        },
    ];

    useEffect(() => {
        debounce(fetchCheckLists, 500)(search);
    }, [paging.page, search, status, currentStore]);

    return (
        <>
            <Table
                data={checkLists}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <DeleteCheckListDialog
                checkList={checkList}
                confirmDeleteDialog={confirmDeleteDialog}
                handleChangeConfirmDeleteDialog={handleChangeConfirmDeleteDialog}
                handleDelete={handleDelete}
                waiting={waiting}
            />
            <OrderDialog
                checkList={checkList}
                orderDialog={orderDialog}
                handleCloseOrderDialog={handleCloseOrderDialog}
                handlePrintOrder={handlePrintOrder}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
            />
            <CancelPaymentDialog
                checkList={checkList}
                confirmCancelDialog={cancelDialog}
                handleCloseCancelDialog={handleCloseCancelDialog}
                handleCancelPayment={handleCancelPayment}
                waiting={waiting}
            />
            <ReactDialog
                checkList={checkList}
                reactDialog={reactDialog}
                handleCloseReactDialog={handleCloseReactDialog}
                handleUpdateReaction={handleUpdateReaction}
                reaction={reaction}
                setReaction={setReaction}
                waiting={waiting}
            />
            <div style={{ display: "none" }}>
                <ReceiptSheet ref={componentRef} checkList={checkList} paymentType={paymentType} />
            </div>
        </>
    );
};

export default RegisteredList;
