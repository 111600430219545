import { styled } from "@mui/material/styles";
import { COLOR } from "../../styles/color";
import { Box } from "@mui/material";

export const StyledGroupPermission = styled(Box)`
    .warper {
        border-right: 1px solid ${COLOR.dark};
        height: 580px;
        padding-right: 10px;
    }
    .option-field-content {
        max-height: 525px;
        overflow-y: auto;
        margin-bottom: 20px;
        ::-webkit-scrollbar {
            width: 4px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px ${COLOR.borderDark};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${COLOR.lightBlue};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${COLOR.primaryDark};
        }
    }
`;
