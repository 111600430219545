import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import vaccineSlice from "./vaccineSlice";
import checkListSlice from "./checkListSlice";
import userSlice from "./userSlice";
import storeSlice from "./storeSlice";
import roleSlice from "./roleSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        vaccine: vaccineSlice,
        checkList: checkListSlice,
        user: userSlice,
        store: storeSlice,
        role: roleSlice,
    },
});
