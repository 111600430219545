import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const generateAge = (dateOfBirth) => {
    let birthday = dayjs(dateOfBirth, "DD/MM/YYYY");
    const now = dayjs();
    const years = now.diff(birthday, "year");
    birthday = birthday.add(years, "year");

    const months = now.diff(birthday, "month");
    birthday = birthday.add(months, "month");

    const days = now.diff(birthday, "day");

    return `${years} tuổi ${months} tháng ${days} ngày`;
};

const getAge = (dateOfBirth, milestone) => {
    let birthday = dayjs(dateOfBirth, "DD/MM/YYYY");
    const now = dayjs(milestone);
    const years = now.diff(birthday, "year");
    return years;
};

export { generateAge, getAge };
