import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import Dialog from "../../components/Dialog";
import { renderStatus } from "../../utils/checklist";

const DeleteCheckListDialog = ({
    checkList,
    confirmDeleteDialog,
    handleChangeConfirmDeleteDialog,
    handleDelete,
    waiting,
}) => {
    return (
        <Dialog title="Xác nhận xóa phiếu đăng ký" open={confirmDeleteDialog} onClose={handleChangeConfirmDeleteDialog}>
            <DialogContent sx={{ padding: "10px 15px" }}>
                <Typography marginBottom={2}>Thông tin phiếu</Typography>
                <Box marginBottom={3} marginX={3}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Trạng thái:
                                </Typography>
                                {renderStatus(checkList?.status)}
                            </Box>
                        </Grid>

                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Họ và tên:
                                </Typography>
                                <Typography>{checkList.customer?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Số phiếu:
                                </Typography>
                                <Typography>{checkList.order}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Giới tính:
                                </Typography>
                                <Typography>{!checkList.customer?.gender ? "Nam" : "Nữ"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Thời gian tạo:
                                </Typography>
                                <Typography>{dayjs(checkList.createdAt).format("DD/MM/YYYY HH:mm")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} marginRight={1}>
                                    Ngày sinh:
                                </Typography>
                                <Typography>{checkList.customer?.dateOfBirth}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Typography color="error" fontStyle="italic" align="center">
                    * Phiếu đăng ký này sẽ bị xóa.
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleChangeConfirmDeleteDialog}>
                    Hủy
                </Button>
                <LoadingButton variant="contained" color="error" onClick={handleDelete} loading={waiting}>
                    Xóa
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCheckListDialog;
