import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";

import Dialog from "../../components/Dialog";
import { INIT_STORE, INIT_STORE_ERROR, STORE_STATUS } from "../../constants/store";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { setStores } from "../../stores/storeSlice";

const CreateStoreDialog = ({
    createStoreDialog,
    handleCloseCreateStoreDialog,
    store,
    setStore,
    handleReload,
    dialogType,
}) => {
    const [storeError, setStoreError] = useState(INIT_STORE_ERROR);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);

    const dispatch = useDispatch();

    const handChangeStore = (event, field) => {
        const value = event.target.value;
        setStore((prev) => ({ ...prev, [field]: value }));
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.checked);
    };

    const handleCloseDialog = () => {
        handleCloseCreateStoreDialog();
        setStoreError(INIT_STORE_ERROR);
        setStore({});
        handleReload();
    };

    const getStores = async () => {
        try {
            const res = await apis.store.getStores({ status: STORE_STATUS.ACTIVE });
            dispatch(setStores(res.stores));
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...storeError };
        Object.keys(INIT_STORE).forEach((key) => {
            if (store[key] === INIT_STORE[key]) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setStoreError(error);
        return isSuccess;
    };

    const handleCreateStore = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            await apis.store.createStore(store);
            getStores();
            handleCloseDialog();
            toast.success("Thêm phòng tiêm thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleUpdateStore = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            const res = await apis.store.updateStore(store.id, {
                ...store,
                status: status ? STORE_STATUS.ACTIVE : STORE_STATUS.INACTIVE,
            });
            if (res.status === 1) {
                getStores();
                handleCloseDialog();
                toast.success("Cập nhật thông tin thành công");
            } else {
                toast.error("Cập nhật thông tin thất bại");
            }
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setStatus(store?.status === STORE_STATUS.ACTIVE);
    }, [store]);

    return (
        <Dialog
            title={dialogType === "create" ? "Thêm phòng tiêm" : "Cập nhật phòng tiêm"}
            maxWidth="md"
            open={createStoreDialog}
            onClose={handleCloseDialog}
        >
            <DialogContent>
                {store && Object.keys(store).length > 0 && (
                    <Box>
                        <Box mb={3}>
                            <Typography fontWeight={500} mb={1}>
                                Tên phòng tiêm:
                            </Typography>
                            <TextField
                                error={storeError.name}
                                value={store.name}
                                size="small"
                                fullWidth
                                onChange={(event) => handChangeStore(event, "name")}
                                placeholder="Tên phòng tiêm"
                            />
                        </Box>
                        <Box mb={3}>
                            <Typography fontWeight={500} mb={1}>
                                Hotline:
                            </Typography>
                            <TextField
                                error={storeError.hotline}
                                value={store.hotline}
                                size="small"
                                fullWidth
                                onChange={(event) => handChangeStore(event, "hotline")}
                                placeholder="Hotline"
                            />
                        </Box>
                        <Box mb={2}>
                            <Typography fontWeight={500} mb={1}>
                                Địa chỉ phòng tiêm:
                            </Typography>
                            <TextField
                                error={storeError.address}
                                value={store.address}
                                size="small"
                                fullWidth
                                onChange={(event) => handChangeStore(event, "address")}
                                placeholder="Địa chỉ phòng tiêm"
                                multiline={true}
                                rows={3}
                            />
                        </Box>
                        {dialogType === "update" && (
                            <Stack mb={3} direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Typography fontWeight={500}>Trạng thái:</Typography>
                                <FormControlLabel
                                    control={<Switch checked={status} onChange={handleChangeStatus} />}
                                    label={status ? "Hoạt động" : "Không hoạt động"}
                                />
                            </Stack>
                        )}
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                    Hủy
                </Button>
                {dialogType === "create" ? (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleCreateStore}>
                        Thêm mới
                    </LoadingButton>
                ) : (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleUpdateStore}>
                        Lưu thay đổi
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CreateStoreDialog;
