import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "../utils/cookie";
import axiosClient from "../apis/api";
import { setCookie } from "../utils/cookie";
import { A_WEEK } from "../constants";

const initialState = {
    accessToken: getCookie("accessToken") || null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            const accessToken = action.payload;
            axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            setCookie("accessToken", accessToken, A_WEEK);
            state.accessToken = accessToken;
        },
        logout: (state) => {
            setCookie("accessToken", null, 1);
            state.accessToken = null;
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
