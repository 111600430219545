import * as auth from "./auth";
import * as customer from "./customer";
import * as checkList from "./checkList";
import * as vaccine from "./vaccine";
import * as checkListVaccine from "./checkListVaccine";
import * as history from "./history";
import * as address from "./address";
import * as store from "./store";
import * as warehouse from "./warehouse";
import * as groupPermission from "./groupPermission";
import * as permission from "./permission";
import * as role from "./role";
import * as user from "./user";

const allApi = {
    auth,
    customer,
    checkList,
    vaccine,
    checkListVaccine,
    history,
    address,
    store,
    warehouse,
    groupPermission,
    permission,
    role,
    user,
};

export default allApi;
