import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInjection = styled(Box)`
    padding: 24px;

    .filter {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 10px;
        margin-bottom: 1rem;

        .search {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 10px;

            .status-select {
                width: 180px;
            }
        }
    }

    .table {
        margin: 20px 0;
    }
`;
