import { Box, Dialog, styled } from "@mui/material";

import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";
import { BORDER_RADIUS, BOX_SHADOW } from "../../styles/config";

export const StyledDialog = styled(Dialog)`
    .dialog-content {
        padding: 0;
        background-color: ${TRANSPARENT_COLOR.light};

        .content {
            padding: 15px 10px;
        }

        .actions,
        .personal-info,
        .history {
            background-color: ${COLOR.white};
            box-shadow: ${BOX_SHADOW};
            border-radius: ${BORDER_RADIUS};
        }

        .personal-info,
        .history {
            padding: 15px 20px;
            margin-bottom: 15px;

            .title {
                margin-bottom: 10px;
            }
        }
    }
`;

export const StyledExamination = styled(Box)`
    .fw-bold {
        font-weight: 500;
    }

    .fst-italic {
        font-style: italic;
    }
`;

export const StyledAssignInjection = styled(Box)`
    .injection-service {
        padding: 10px 15px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        background-color: ${TRANSPARENT_COLOR.primary};
    }
`;

export const StyledSchedule = styled(Box)`
    .injection-service,
    .schedule-history {
        padding: 10px 15px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    .injection-service {
        background-color: ${TRANSPARENT_COLOR.primary};
    }

    .schedule-history {
        background-color: ${TRANSPARENT_COLOR.light};
    }
`;
