import api from "./api";

const getHistories = async (customerId) => {
    const response = await api({
        method: "GET",
        url: `/histories/${customerId}`,
    });

    return response;
};

export { getHistories };
