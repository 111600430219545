import api from "./api";

const getPermissions = async (params) => {
    const response = await api({
        method: "GET",
        url: "/permissions",
        params,
    });

    return response;
};

const createPermission = async (data) => {
    const response = await api({
        method: "POST",
        url: "/permissions",
        data,
    });

    return response;
};

const updatePermission = async (permissionId, data) => {
    const response = await api({
        method: "PUT",
        url: `/permissions/${permissionId}`,
        data,
    });

    return response;
};

const deletePermission = async (permissionId) => {
    const response = await api({
        method: "DELETE",
        url: `/permissions/${permissionId}`,
    });

    return response;
};

export { getPermissions, createPermission, updatePermission, deletePermission };
