import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import HistoryIcon from "@mui/icons-material/History";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import NoData from "../../components/NoData";

const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {dayjs(row.injectedAt).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{row.vaccine}</TableCell>
                <TableCell align="center">{row.numberOfInjections}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Chi tiết
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Ngày tiêm:
                                </Typography>
                                <Typography>{dayjs(row.injectedAt).format("DD/MM/YYYY")}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Vắc xin:
                                </Typography>
                                <Typography>{row.vaccine}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Mũi số:
                                </Typography>
                                <Typography>{row.numberOfInjections}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Trạng thái:
                                </Typography>
                                <Typography>{row.status}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Phản ứng sau tiêm:
                                </Typography>
                                <Typography>{row.reaction}</Typography>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const History = () => {
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);

    const checkList = useSelector((state) => state.checkList);

    const fetchHistories = async (customerId) => {
        setLoading(true);
        try {
            const data = await apis.history.getHistories(customerId);
            setHistories(data?.histories || []);
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistories(checkList?.customerId);
    }, [checkList]);

    return (
        <Box sx={{ flexGrow: 1 }} className="history">
            <Stack direction="row" spacing={1} alignItems="center" className="title" mb={2}>
                <HistoryIcon fontSize="large" />
                <Typography variant="h6">Lịch sử tiêm</Typography>
            </Stack>

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {histories.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Ngày tiêm</TableCell>
                                        <TableCell>Vắc xin</TableCell>
                                        <TableCell align="center">Mũi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {histories.map((history, index) => (
                                        <Row key={index} row={history} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoData />
                    )}
                </>
            )}
        </Box>
    );
};

export default History;
