import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";

import { StyledExamination } from "../index.style";
import { surveyAdultContents, adultNotes } from "../config";

const AdultExamination = ({ examination, setExamination, canEdit }) => {
    const handleChangeHypothermia = (event) => {
        const { value } = event.target;
        setExamination((prev) => ({ ...prev, hypothermia: value }));
    };
    const handleChangeBloodPressure = (event) => {
        const { value } = event.target;
        setExamination((prev) => ({ ...prev, bloodPressure: value }));
    };
    const handleChangeHeartbeat = (event) => {
        const { value } = event.target;
        setExamination((prev) => ({ ...prev, heartbeat: value }));
    };
    const handleChangeBreathing = (event) => {
        const { value } = event.target;
        setExamination((prev) => ({ ...prev, breathing: value }));
    };
    const handleChangeSurvey = (event, index) => {
        let survey = [...examination.survey];
        survey[index] = parseInt(event.target.value);
        setExamination((prev) => ({ ...prev, survey }));
    };
    const handleChangeConclusion = (event) => {
        const { value } = event.target;
        setExamination((prev) => ({ ...prev, conclusion: parseInt(value) }));
    };
    const handleChangeNote = (event, index) => {
        let notes = [...examination.notes];
        notes[index] = parseInt(event.target.value);
        setExamination((prev) => ({ ...prev, notes }));
    };

    return (
        <StyledExamination>
            <Box marginBottom={1}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={3}>
                        <Typography mb={1}>Thân nhiệt:</Typography>
                        <TextField
                            value={examination.hypothermia}
                            size="small"
                            fullWidth
                            onChange={handleChangeHypothermia}
                            placeholder="&ordm; C"
                            disabled={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={2} sm={4} md={3}>
                        <Typography mb={1}>Huyết áp:</Typography>
                        <TextField
                            value={examination.bloodPressure}
                            size="small"
                            fullWidth
                            onChange={handleChangeBloodPressure}
                            placeholder="mmHg"
                            disabled={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={2} sm={4} md={3}>
                        <Typography mb={1}>Nhịp tim:</Typography>
                        <TextField
                            value={examination.heartbeat}
                            size="small"
                            fullWidth
                            onChange={handleChangeHeartbeat}
                            placeholder="lần/phút"
                            disabled={!canEdit}
                        />
                    </Grid>
                    <Grid item xs={2} sm={4} md={3}>
                        <Typography mb={1}>Nhịp thở</Typography>
                        <TextField
                            value={examination.breathing}
                            size="small"
                            fullWidth
                            onChange={handleChangeBreathing}
                            placeholder="lần/phút"
                            disabled={!canEdit}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box marginBottom={2}>
                {surveyAdultContents.map((content, index) => (
                    <Grid
                        key={uuidv4()}
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        style={{ alignItems: "center" }}
                    >
                        <>
                            <Grid item xs={2} sm={4} md={9}>
                                <Typography>{content}</Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={3} sx={{ display: "flex", justifyContent: "end" }}>
                                <RadioGroup
                                    row
                                    value={examination.survey[index]}
                                    onChange={(event) => handleChangeSurvey(event, index)}
                                >
                                    <FormControlLabel disabled={!canEdit} value={1} control={<Radio />} label="Có" />
                                    <FormControlLabel disabled={!canEdit} value={0} control={<Radio />} label="Không" />
                                </RadioGroup>
                            </Grid>
                        </>
                    </Grid>
                ))}
            </Box>
            <Box mb={1}>
                <Typography>
                    <span className="fw-bold">Kết luận:</span>{" "}
                    <span className="fw-bold fst-italic">Có đủ điều kiện tiêm chủng ngay</span>{" "}
                    <span className="fst-italic">
                        (Tất cả đều <span className="fw-bold">KHÔNG</span> có điểm bất thường)?
                    </span>
                </Typography>
                <RadioGroup row value={examination.conclusion} onChange={handleChangeConclusion}>
                    <FormControlLabel disabled={!canEdit} value={1} control={<Radio />} label="Có" />
                    <FormControlLabel disabled={!canEdit} value={0} control={<Radio />} label="Không" />
                </RadioGroup>
            </Box>

            {adultNotes.map((note, index) => (
                <Grid key={uuidv4()} container columns={{ xs: 4, sm: 8, md: 12 }} style={{ alignItems: "center" }}>
                    <Grid item xs={2} sm={4} md={9}>
                        <Typography fontStyle="italic">{note}</Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} md={3} sx={{ display: "flex", justifyContent: "end" }}>
                        <RadioGroup
                            row
                            value={examination.notes[index]}
                            onChange={(event) => handleChangeNote(event, index)}
                        >
                            <FormControlLabel disabled={!canEdit} value={1} control={<Radio />} label="Có" />
                            <FormControlLabel disabled={!canEdit} value={0} control={<Radio />} label="Không" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            ))}
        </StyledExamination>
    );
};

export default AdultExamination;
