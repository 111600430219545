const VACCINE_STATUS = {
    ACTIVE: "active",
    INACTIVE: "inactive",
};

const INT_VACCINE = {
    name: "",
    desc: "",
    price: "0",
    cost: "0",
    origin: "",
    dosage: "",
    method: "",
};

const INT_VACCINE_ERROR = {
    name: false,
    desc: false,
    price: false,
    cost: false,
    origin: false,
    dosage: false,
    method: false,
};

const INIT_ASSIGN_VACCINE = {
    vaccineId: null,
    numberOfInjections: "1",
    dosage: "none",
    discount: 0,
};

const INIT_ASSIGN_VACCINE_ERROR = {
    vaccineId: false,
    dosage: false,
};

const INIT_SCHEDULE_VACCINE = {
    vaccineId: null,
    numberOfInjections: "1",
    schedule: null,
    note: "",
};

const INIT_SCHEDULE_VACCINE_ERROR = {
    vaccineId: false,
    schedule: false,
};

const NUMBER_OF_INJECTION = ["1", "2", "3", "4", "5", "6", "Bổ sung"];

export {
    INIT_ASSIGN_VACCINE,
    INIT_ASSIGN_VACCINE_ERROR,
    INIT_SCHEDULE_VACCINE,
    INIT_SCHEDULE_VACCINE_ERROR,
    NUMBER_OF_INJECTION,
    VACCINE_STATUS,
    INT_VACCINE,
    INT_VACCINE_ERROR,
};
