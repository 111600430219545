import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

import apis from "../apis";
import { logout } from "../stores/authSlice";
import { handleCallApiError } from "../errors";
import { setCurrentUser } from "../stores/userSlice";
import { FormControl, Select } from "@mui/material";
import { setCurrentStore } from "../stores/storeSlice";
import { ALL_STORE, ROLE_ADMIN_KEY } from "../constants";

const Header = (props) => {
    const { onDrawerToggle } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user);
    const store = useSelector((state) => state.store);

    const handleChangeStore = (event) => {
        dispatch(setCurrentStore(event.target.value));
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await apis.auth.logout();
            dispatch(logout());
            dispatch(setCurrentUser({}));
            toast.success("Đăng xuất thành công");
        } catch (error) {
            handleCallApiError(error);
        }
    };

    return (
        <Fragment>
            <AppBar component="div" color="primary" position="static" elevation={0} sx={{ zIndex: 0 }}>
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
                            <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            {currentUser?.role?.key === ROLE_ADMIN_KEY ? (
                                <FormControl>
                                    <Select
                                        value={store.currentStore}
                                        onChange={handleChangeStore}
                                        size="small"
                                        sx={{ color: "#4fc3f7", width: "200px", backgroundColor: "#ffffff" }}
                                    >
                                        <MenuItem value={ALL_STORE}>Tất cả</MenuItem>
                                        {store.stores.map((st) => (
                                            <MenuItem key={st.id} value={st.id}>
                                                {st.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <Typography>{currentUser?.store?.name}</Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <Typography>{currentUser?.name}</Typography>
                        </Grid>
                        <Grid item>
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>Hồ sơ cá nhân</MenuItem>
                                    <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};

export default Header;
