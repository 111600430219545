const INITIAL_CUSTOMER_ERROR = {
    name: false,
    dateOfBirth: false,
    phoneNumber: false,
    parentName: false,
    provinceId: false,
    districtId: false,
    wardId: false,
};

const INITIAL_CUSTOMER = {
    name: "",
    gender: 0,
    dateOfBirth: null,
    phoneNumber: "",
    parentName: "",
    provinceId: null,
    districtId: null,
    wardId: null,
};

export { INITIAL_CUSTOMER_ERROR, INITIAL_CUSTOMER };
