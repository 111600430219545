import React from "react";
import { useLocation, Link, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
// import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

import appRoutes from "../../routes/appRoutes";
import { item, itemCategory } from "./config";

const Sidebar = (props) => {
    const permissions = useSelector((state) => state.role.permissions);
    const { ...other } = props;
    const location = useLocation();
    return (
        <>
            <Drawer variant="permanent" {...other}>
                <List disablePadding>
                    <ListItem
                        sx={{
                            ...item,
                            ...itemCategory,
                            fontSize: 21.5,
                            color: "#ffffff",
                        }}
                    >
                        Nutivac
                    </ListItem>
                    <Box sx={{ bgcolor: "#101F33" }}>
                        {appRoutes
                            .filter((route) => route.sidebar && permissions.includes(route.path))
                            .map((route) => (
                                <ListItem key={route.path} disablePadding>
                                    <Link to={route.path} className="link">
                                        <ListItemButton
                                            sx={item}
                                            selected={
                                                matchPath(location.pathname, {
                                                    path: route.path,
                                                    exact: true,
                                                }) !== null
                                            }
                                        >
                                            <ListItemIcon>{route.icon}</ListItemIcon>
                                            <ListItemText>{route.name}</ListItemText>
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            ))}
                    </Box>
                </List>
            </Drawer>
        </>
    );
};

export default Sidebar;
