import React from "react";
import { Box, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import CustomPermissionTypeTag from "../../components/PermissionTypeTag";
import { StyledPermissionList } from "./index.style";

const PermissionList = ({ title, permissions = [], onSelectPermission }) => (
    <StyledPermissionList>
        <Typography className="header-title">{title}</Typography>
        <Grid container spacing={2}>
            {permissions.map((permission) => (
                <Grid key={permission.id} item xs={6} className="item">
                    <FormControlLabel
                        control={<Checkbox checked={permission.checked} />}
                        label={
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
                                <CustomPermissionTypeTag type={permission.type} />
                                <Typography>{permission.name}</Typography>
                            </Box>
                        }
                        className="checkbox"
                        onChange={() => onSelectPermission(permission)}
                    />
                </Grid>
            ))}
        </Grid>
    </StyledPermissionList>
);

export default PermissionList;
