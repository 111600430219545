import { Search } from "@mui/icons-material";
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { checkListStatus, CHECK_LIST_STATUS, CHECK_LIST_STATUS_DEFAULT } from "../../../constants/checkList";
import { StyledHistory } from "./index.style";
import MedicalHistory from "./MedicalHistory";

const INITIAL_PAGING = { page: 1, total: 0 };
const History = () => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(CHECK_LIST_STATUS_DEFAULT);
    const [paging, setPaging] = useState(INITIAL_PAGING);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangeStatus = (event) => {
        const { value } = event.target;
        setStatus(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };
    return (
        <>
            <StyledHistory>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm theo Mã tiềm chủng/Họ tên/Tên người bảo hộ/CMND/Số điện thoại"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box>
                            <FormControl>
                                <InputLabel id="status-select-label">Trạng thái</InputLabel>
                                <Select
                                    className="status-select"
                                    labelId="status-select-label"
                                    value={status}
                                    onChange={handleChangeStatus}
                                    size="small"
                                    label="Trạng thái"
                                >
                                    {checkListStatus
                                        .filter((status) => status.value > CHECK_LIST_STATUS.WAITING_FOR_EXAMINATION)
                                        .map((status, index) => (
                                            <MenuItem key={index} value={status.value}>
                                                {status.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <Box></Box>
                </div>
                <MedicalHistory search={search} status={status} paging={paging} onChangePage={handleChangePaging} />
            </StyledHistory>
        </>
    );
};

export default History;
