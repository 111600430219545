import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ALL_STORE } from "../../constants";
import Vaccine from "./Vaccine";

const Warehouse = () => {
    const currentStore = useSelector((state) => state.store.currentStore);
    if (currentStore === ALL_STORE) {
        return (
            <Box mt={3} ml={3}>
                <Typography variant="h6" component="h6">
                    Vui lòng chỉ định phòng tiêm!
                </Typography>
            </Box>
        );
    }

    return <Vaccine />;
};

export default Warehouse;
