import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, Divider, Grid, Stack, Typography } from "@mui/material";

import Dialog from "../../../components/Dialog";
import { renderStatus, renderTotalPrice } from "../../../utils/checklist";
import { formatMoney } from "../../../utils/money";
import { PAYMENT_TYPE } from "../../../constants/payment";

const CancelPaymentDialog = ({
    checkList,
    confirmCancelDialog,
    handleCloseCancelDialog,
    handleCancelPayment,
    waiting,
}) => {
    return (
        <Dialog title="Xác nhận hủy thanh toán" open={confirmCancelDialog} onClose={handleCloseCancelDialog}>
            <DialogContent sx={{ padding: "10px 15px" }}>
                <Typography marginBottom={2}>Thông tin thanh toán</Typography>
                <Box mb={3} marginX={3}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight={500} mr={1}>
                                    Trạng thái:
                                </Typography>
                                {renderStatus(checkList?.status)}
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Họ và tên:
                                </Typography>
                                <Typography>{checkList.customer?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Giới tính:
                                </Typography>
                                <Typography>{!checkList.customer?.gender ? "Nam" : "Nữ"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Ngày sinh:
                                </Typography>
                                <Typography>{checkList.customer?.dateOfBirth}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box display="flex" alignItems="flex-start">
                                <Typography fontWeight={500} mr={2}>
                                    Vắc xin chỉ định:
                                </Typography>
                                <Box>
                                    {checkList.vaccines?.map((vaccine, index) => (
                                        <Typography key={index} mb={1}>{`${index + 1}. ${vaccine.name} | ${
                                            vaccine.desc
                                        } | ${vaccine.origin} | ${formatMoney(
                                            vaccine.detail.price - vaccine.detail.discount
                                        )} VNĐ`}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Thành tiền:
                                </Typography>
                                <Typography>{renderTotalPrice(checkList.vaccines)}</Typography>
                            </Box>
                        </Grid>

                        {checkList.paymentType && (
                            <Grid item xs={2} sm={4} md={12} sx={{ paddingTop: "15px !important" }}>
                                <Stack direction="row" alignItems="center">
                                    <Typography fontWeight={500} mr={1}>
                                        Hình thức thanh toán:
                                    </Typography>

                                    <Typography>
                                        {checkList.paymentType === PAYMENT_TYPE.CASH ? "Tiền mặt" : "Chuyển khoản"}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Typography color="error" fontStyle="italic" align="center">
                    * Thanh toán này sẽ bị hủy.
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseCancelDialog}>
                    Hủy
                </Button>
                <LoadingButton variant="contained" color="error" onClick={handleCancelPayment} loading={waiting}>
                    Xác nhận
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CancelPaymentDialog;
