import styled from "styled-components";

export const LoginWrapper = styled.div`
    width: 350px;
    margin: 0 auto;
    margin-top: 100px;

    .login-title {
        text-align: center;
        margin-bottom: 25px;
    }
`;
