import api from "./api";

const getRole = async (roleId) => {
    const response = await api({
        method: "GET",
        url: `/roles/${roleId}`,
    });

    return response;
};

const getRoles = async (params) => {
    const response = await api({
        method: "GET",
        url: "/roles",
        params,
    });

    return response;
};

const createRole = async (data) => {
    const response = await api({
        method: "POST",
        url: "/roles",
        data,
    });

    return response;
};

const updateRole = async (roleId, data) => {
    const response = await api({
        method: "PUT",
        url: `/roles/${roleId}`,
        data,
    });

    return response;
};

const deleteRole = async (roleId) => {
    const response = await api({
        method: "DELETE",
        url: `/roles/${roleId}`,
    });

    return response;
};

const attachPermissionToRole = async (roleId, permissionIds) => {
    const response = await api({
        method: "PUT",
        url: `/roles/attach-permission/${roleId}`,
        data: { permissionIds },
    });

    return response;
};

export { getRole, getRoles, createRole, updateRole, deleteRole, attachPermissionToRole };
