import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSchedule = styled(Box)`
    .filter {
        display: grid;
        grid-template-columns: 5fr 1fr;
        grid-gap: 15px;
        margin-bottom: 1rem;

        .search {
            display: grid;
            grid-template-columns: 3fr 1fr 2fr;
            grid-gap: 15px;
        }
    }

    .table {
        margin: 20px 0;
    }
`;
