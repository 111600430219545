import WaitingList from "./WaitingList";
import History from "./History";

const tabs = [
    {
        name: "Danh sách chờ",
        value: "1",
        component: <WaitingList />,
    },
    {
        name: "Lịch sử khám",
        value: "2",
        component: <History />,
    },
];

export default tabs;
