import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppBar, Box, DialogContent, Grid, IconButton, Slide, Tab, Toolbar, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import tabs from "./config";
import { StyledDialog } from "./index.style";
import CustomerInfo from "./CustomerInfo";
import History from "./History";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExaminationDialog = ({ open, handleClose }) => {
    const [value, setValue] = useState("1");
    const checkList = useSelector((state) => state.checkList);

    const onClose = () => {
        setValue("1");
        handleClose();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <StyledDialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <DialogContent className="dialog-content">
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {`Khách hàng: ${checkList?.customer?.name} - ${checkList?.customer?.id}`}
                        </Typography>
                        <Tooltip title="Đóng">
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Box className="content">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={8}>
                            <Box sx={{ width: "100%", typography: "body1" }} className="actions">
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            {tabs.map((tab) => (
                                                <Tab label={tab.name} value={tab.value} key={tab.value} />
                                            ))}
                                        </TabList>
                                    </Box>
                                    {tabs.map((tab) => (
                                        <TabPanel key={tab.value} value={tab.value}>
                                            {tab.component}
                                        </TabPanel>
                                    ))}
                                </TabContext>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <CustomerInfo />
                            <History />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </StyledDialog>
    );
};

export default ExaminationDialog;
