import api from "./api";

const ADDRESS_URL = "https://dvhc.tiemchungnutivac.com";

const getProvinces = async () => {
    const response = await api({
        method: "GET",
        url: `${ADDRESS_URL}/api/provinces`,
    });

    return response;
};

const getDistricts = async (provinceId) => {
    const response = await api({
        method: "GET",
        url: `${ADDRESS_URL}/api/districts`,
        params: {
            province_id: provinceId,
        },
    });

    return response;
};

const getWards = async (districtId) => {
    const response = await api({
        method: "GET",
        url: `${ADDRESS_URL}/api/wards`,
        params: {
            district_id: districtId,
        },
    });

    return response;
};

export { getProvinces, getDistricts, getWards };
