import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Search, Autorenew, Add, ExpandMore, Refresh } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";

import { StyledWrapperCustomers, StyledCustomers } from "./index.style";
import Dialog from "../../../components/Dialog";
import { COLOR } from "../../../styles/color";
import CustomerList from "./CustomerList";
import apis from "../../../apis";
import { handleCallApiError } from "../../../errors";
import { TOOLTIP_BOX_SHADOW } from "../../../styles/config";
import NoData from "../../../components/NoData";
import CreateCheckListDialog from "../../CreateCheckListDialog";
import CreateCustomerDialog from "../../CreateCustomerDialog";
import { INITIAL_CUSTOMER } from "../../../constants/customer";

const INITIAL_PAGING = { page: 1, total: 0 };

const Customers = () => {
    const [search, setSearch] = useState("");
    const [searchAdvanceDialog, setSearchAdvanceDialog] = useState(false);
    const [searchAdvance, setSearchAdvance] = useState("");
    const [searchAdvanceError, setSearchAdvanceError] = useState(false);
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [registrationDialog, setRegistrationDialog] = useState(false);
    const [customer, setCustomer] = useState({});
    const [reload, setReload] = useState(false);
    const [customerDialog, setCustomerDialog] = useState(false);
    const [newCustomer, setNewCustomer] = useState({});

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
        setPaging(INITIAL_PAGING);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleRefresh = () => {
        setCustomer({});
        setSearchAdvance("");
        setNoData(false);
        setSearchAdvanceError(false);
    };

    const handleChangeSearchAdvanceDialog = () => {
        handleRefresh();
        setSearchAdvanceDialog((prev) => !prev);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSearchAdvance();
        }
    };

    const handleChangeSearchAdvance = (event) => {
        setSearchAdvance(event.target.value);
        setNoData(false);
        setSearchAdvanceError(false);
    };

    const handleSearchAdvance = async () => {
        if (searchAdvance === "") {
            setSearchAdvanceError(true);
            return;
        }
        setLoading(true);
        try {
            const res = await apis.customer.searchAdvance(searchAdvance);
            setCustomer(res.customer);
            if (Object.keys(res.customer).length === 0) setNoData(true);
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleChangeExpand = () => {
        setExpanded((prev) => !prev);
    };

    const handleReload = () => {
        setReload((prev) => !prev);
    };

    const handleOpenRegistrationDialog = () => {
        setSearchAdvanceDialog(false);
        setRegistrationDialog(true);
    };

    const handleCloseRegistrationDialog = () => {
        setSearchAdvanceDialog(false);
        handleRefresh();
        setRegistrationDialog(false);
        handleReload();
    };

    const handleCloseCustomerDialog = () => {
        setCustomerDialog(false);
    };

    const handleOpenCustomerDialog = () => {
        setNewCustomer(INITIAL_CUSTOMER);
        setCustomerDialog(true);
    };

    return (
        <StyledWrapperCustomers>
            <StyledCustomers>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm theo Mã tiềm chủng/Họ tên/Tên người bảo hộ/CMND/Số điện thoại"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box>
                            <Button
                                variant="outlined"
                                sx={{
                                    paddingTop: "7px",
                                    paddingBottom: "6.5px",
                                }}
                                onClick={() => setSearchAdvanceDialog(true)}
                            >
                                Tìm kiếm nâng cao
                            </Button>
                        </Box>
                    </div>
                    <Box display="flex" justifyContent="end">
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            color="success"
                            sx={{ paddingTop: "7px", paddingBottom: "6.5px", marginRight: "5px" }}
                            onClick={handleOpenCustomerDialog}
                        >
                            Thêm mới đối tượng
                        </Button>
                        <Tooltip title="Tải lại">
                            <IconButton aria-label="refresh" onClick={handleReload}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>
                <CustomerList search={search} paging={paging} onChangePage={handleChangePaging} reload={reload} />
            </StyledCustomers>
            <Dialog title="Tìm kiếm nâng cao" onClose={handleChangeSearchAdvanceDialog} open={searchAdvanceDialog}>
                <DialogContent sx={{ padding: "10px 15px" }}>
                    <Box mb={3}>
                        <Typography fontWeight={500} mb={1}>
                            Mã tiềm chủng:
                        </Typography>
                        <TextField
                            error={searchAdvanceError}
                            value={searchAdvance}
                            size="small"
                            fullWidth
                            onChange={handleChangeSearchAdvance}
                            placeholder="Mã tiềm chủng"
                            helperText={searchAdvanceError ? "Nhập mã tiêm chủng" : ""}
                            onKeyPress={handleKeyPress}
                        />
                    </Box>
                    <Box mb={3}>
                        {Object.keys(customer).length !== 0 && (
                            <Accordion
                                sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}
                                expanded={expanded}
                                onChange={handleChangeExpand}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{`${1}. ${customer.name} - ${customer.dateOfBirth}`}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} mb={1}>
                                        <Grid item xs={6}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    Mã tiêm chủng:
                                                </Typography>
                                                <Typography>{customer.id}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    Giới tính:
                                                </Typography>
                                                <Typography>{!customer.gender ? "Nam" : "Nữ"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    Họ và tên:
                                                </Typography>
                                                <Typography>{customer.name}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    Ngày sinh:
                                                </Typography>
                                                <Typography>{customer.dateOfBirth}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    Phụ huynh:
                                                </Typography>
                                                <Typography>{customer.parentName}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box display="flex">
                                                <Typography fontWeight={500} marginRight={1}>
                                                    SĐT:
                                                </Typography>
                                                <Typography>{customer.phoneNumber}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                        <Button size="small" variant="contained" onClick={handleOpenRegistrationDialog}>
                                            Tạo phiếu ngay
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {noData && <NoData />}
                    </Box>
                    <Typography color={COLOR.dark} fontStyle="italic">
                        * Tính năng này sẽ thực hiện tìm kiếm trên cơ sở dữ liệu tiêm chủng mở rộng.
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <LoadingButton variant="contained" onClick={handleSearchAdvance} loading={loading}>
                        Tìm kiếm
                    </LoadingButton>
                    <Tooltip title="Đặt lại">
                        <IconButton aria-label="refresh" onClick={handleRefresh}>
                            <Autorenew />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
            <CreateCheckListDialog
                handleCloseRegistrationDialog={handleCloseRegistrationDialog}
                registrationDialog={registrationDialog}
                customer={customer}
            />
            <CreateCustomerDialog
                handleCloseCustomerDialog={handleCloseCustomerDialog}
                customerDialog={customerDialog}
                customer={newCustomer}
                setCustomer={setNewCustomer}
                handleReload={handleReload}
                dialogType="create"
            />
        </StyledWrapperCustomers>
    );
};

export default Customers;
