import HomeIcon from "@mui/icons-material/Home";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
// import PaymentIcon from "@mui/icons-material/Payment";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import StoreIcon from "@mui/icons-material/Store";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import ROUTE from "../constants/route";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Receptionist from "../pages/Receptionist";
import Doctor from "../pages/Doctor";
// import Cashier from "../pages/Cashier";
import Injection from "../pages/Injection";
import Store from "../pages/Store";
import Vaccine from "../pages/Vaccine";
import Warehouse from "../pages/Warehouse";
import User from "../pages/User";
import Role from "../pages/Role";
import Permission from "../pages/Permission";

const appRoutes = [
    {
        name: "Trang chủ",
        path: ROUTE.DASHBOARD,
        component: Dashboard,
        icon: <HomeIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Đăng nhập",
        path: ROUTE.LOGIN,
        component: Login,
        icon: <HomeIcon />,
        restricted: true,
        isPrivate: false,
        sidebar: false,
    },
    {
        name: "Lễ tân",
        path: ROUTE.RECEPTIONIST,
        component: Receptionist,
        icon: <SupportAgentIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Bác sĩ",
        path: ROUTE.DOCTOR,
        component: Doctor,
        icon: <VolunteerActivismIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    // {
    //     name: "Thu ngân",
    //     path: ROUTE.CASHIER,
    //     component: Cashier,
    //     icon: <PaymentIcon />,
    //     restricted: false,
    //     isPrivate: true,
    //     sidebar: true,
    // },
    {
        name: "Tiêm",
        path: ROUTE.INJECTION,
        component: Injection,
        icon: <AirlineSeatReclineNormalIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Phòng tiêm",
        path: ROUTE.STORE,
        component: Store,
        icon: <StoreIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Vắc xin",
        path: ROUTE.VACCINE,
        component: Vaccine,
        icon: <VaccinesIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Kho",
        path: ROUTE.WAREHOUSE,
        component: Warehouse,
        icon: <WarehouseIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Nhân sự",
        path: ROUTE.USER,
        component: User,
        icon: <PeopleIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Vai trò",
        path: ROUTE.ROLE,
        component: Role,
        icon: <AdminPanelSettingsIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
    {
        name: "Phân quyền",
        path: ROUTE.PERMISSION,
        component: Permission,
        icon: <VerifiedUserIcon />,
        restricted: false,
        isPrivate: true,
        sidebar: true,
    },
];

export default appRoutes;
