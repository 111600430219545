import React, { useState } from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";

import Dialog from "../../../components/Dialog";
import { renderStatus, renderTotalPrice } from "../../../utils/checklist";
import { formatMoney } from "../../../utils/money";
import { PAYMENT_TYPE } from "../../../constants/payment";
import apis from "../../../apis";
import { CHECK_LIST_STATUS } from "../../../constants/checkList";
import { handleCallApiError } from "../../../errors";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const OrderDialog = ({
    checkList,
    orderDialog,
    handleCloseOrderDialog,
    handlePrintOrder,
    paymentType,
    setPaymentType,
}) => {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangePaymentType = (event) => {
        setPaymentType(event.target.value);
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, {
                status: CHECK_LIST_STATUS.WAITING_FOR_INJECTION,
                paymentType,
            });
            handleChangeEdit();
            toast.success("Lưu thanh toán thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleChangeEdit = () => {
        setEdit((prev) => !prev);
    };

    return (
        <Dialog title="Chi tiết thanh toán" open={orderDialog} onClose={handleCloseOrderDialog}>
            <DialogContent sx={{ padding: "10px 15px" }}>
                <Typography marginBottom={2}>Thông tin thanh toán</Typography>
                <Box mb={3} marginX={3}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight={500} mr={1}>
                                    Trạng thái:
                                </Typography>
                                {renderStatus(checkList?.status)}
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Họ và tên:
                                </Typography>
                                <Typography>{checkList.customer?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box display="flex" alignItems="flex-start">
                                <Typography fontWeight={500} mr={2}>
                                    Vắc xin chỉ định:
                                </Typography>
                                <Box>
                                    {checkList.vaccines?.map((vaccine, index) => (
                                        <Typography key={index} mb={1}>{`${index + 1}. ${vaccine.name} | ${
                                            vaccine.desc
                                        } | ${vaccine.origin} | ${formatMoney(
                                            vaccine.detail.price - vaccine.detail.discount
                                        )} VNĐ`}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Thành tiền:
                                </Typography>
                                <Typography>{renderTotalPrice(checkList.vaccines)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12} sx={{ paddingTop: "15px !important" }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography fontWeight={500} mr={1}>
                                    Hình thức thanh toán:
                                </Typography>
                                {edit ? (
                                    <RadioGroup row value={paymentType} onChange={handleChangePaymentType}>
                                        <FormControlLabel
                                            value={PAYMENT_TYPE.CASH}
                                            control={<Radio />}
                                            label="Tiền mặt"
                                        />
                                        <FormControlLabel
                                            value={PAYMENT_TYPE.TRANSFER}
                                            control={<Radio />}
                                            label="Chuyển khoản"
                                        />
                                    </RadioGroup>
                                ) : (
                                    <Typography>
                                        {paymentType === PAYMENT_TYPE.CASH ? "Tiền mặt" : "Chuyển khoản"}
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            {edit ? (
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleChangeEdit}>
                        Hủy
                    </Button>
                    <LoadingButton variant="contained" onClick={handleUpdate} loading={loading}>
                        Cập nhật
                    </LoadingButton>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseOrderDialog}>
                        Hủy
                    </Button>
                    <Button variant="outlined" color="success" onClick={handleChangeEdit}>
                        Cập nhật PTTT
                    </Button>
                    <Button variant="contained" onClick={handlePrintOrder}>
                        In hóa đơn
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default OrderDialog;
