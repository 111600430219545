import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Autorenew, Search, Description } from "@mui/icons-material";
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { StyledSchedule } from "./index.style";
import {
    APPOINTMENT_HISTORY_ALL_STATUS,
    APPOINTMENT_HISTORY_STATUS,
    APPOINTMENT_HISTORY_STATUS_DEFAULT,
} from "../../../constants/checkList";
import ScheduleList from "./ScheduleList";
import DatePickerRange from "../../../components/PickDateRange";
import apis from "../../../apis";
import { handleCallApiError } from "../../../errors";
import { toast } from "react-toastify";
import { exportExcel } from "../../../utils/excel";
import { ALL_STORE } from "../../../constants";

const INITIAL_PAGING = { page: 1, total: 0 };
const INITIAL_DATE_FILTER = [dayjs().startOf("day"), dayjs().endOf("day")];

const Schedule = () => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(APPOINTMENT_HISTORY_STATUS_DEFAULT);
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [date, setDate] = useState(INITIAL_DATE_FILTER);
    const [loading, setLoading] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangeStatus = (event) => {
        setStatus(parseInt(event.target.value, 10));
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleRefresh = () => {
        setDate(INITIAL_DATE_FILTER);
    };

    const heads = {
        stt: "STT",
        status: "Trạng thái",
        schedule: "Ngày hẹn",
        customerId: "Mã tiêm chủng",
        name: "Họ tên",
        dateOfBirth: "Ngày sinh",
        gender: "Giới tính",
        parentName: "Phụ huynh",
        phoneNumber: "Số điện thoại",
        vaccines: "Vắc xin",
        createdAt: "Ngày tạo",
        createdBy: "Tạo bởi",
    };

    const handleExportExcel = async () => {
        try {
            setLoading(true);
            const [start, end] = date;
            const condition = {
                search,
                start,
                end,
                status: status === APPOINTMENT_HISTORY_ALL_STATUS ? null : status,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const { appointmentsHistory } = await apis.checkList.getCustomerAppointmentsHistory(condition);

            if (appointmentsHistory.length === 0) {
                setLoading(false);
                toast.warn("Không có lịch hẹn nào");
                return;
            }

            const readableData = appointmentsHistory.map((row, index) => ({
                stt: index + 1,
                status: APPOINTMENT_HISTORY_STATUS[row.status].label,
                schedule: dayjs(row.schedule).format("DD/MM/YYYY"),
                customerId: row.customer.id,
                name: row.customer.name,
                dateOfBirth: row.customer.dateOfBirth,
                gender: !row.gender ? "Nam" : "Nữ",
                parentName: row.customer.parentName,
                phoneNumber: row.customer.phoneNumber,
                vaccines: `${row.vaccines[0].name} | ${row.vaccines[0].desc}`,
                createdAt: dayjs(row.createdAt).format("DD/MM/YYYY"),
                createdBy: row.createdBy,
            }));

            const csvData = readableData.map((data) => {
                const mappingData = {};
                Object.keys(heads).forEach((key) => (mappingData[heads[key]] = data[key]));
                return mappingData;
            });
            const fileName = `Lich_hen_tiem_${dayjs().format("DD.MM.YYYY")}.xlsx`;

            exportExcel(csvData, fileName, Object.values(heads));
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    return (
        <>
            <StyledSchedule>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm theo Mã tiềm chủng/Họ tên/Tên người bảo hộ/Số điện thoại"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="status-select-label">Trạng thái</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    value={status}
                                    onChange={handleChangeStatus}
                                    size="small"
                                    label="Trạng thái"
                                >
                                    {Object.keys(APPOINTMENT_HISTORY_STATUS).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {APPOINTMENT_HISTORY_STATUS[key].label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display="flex">
                            <DatePickerRange value={date} onChange={setDate} />
                            <Tooltip title="Đặt lại">
                                <IconButton aria-label="refresh" onClick={handleRefresh}>
                                    <Autorenew />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </div>
                    <Box display="flex" justifyContent="end">
                        <LoadingButton
                            color="success"
                            variant="outlined"
                            startIcon={<Description />}
                            loading={loading}
                            onClick={handleExportExcel}
                        >
                            Xuất Excel
                        </LoadingButton>
                    </Box>
                </div>
                <ScheduleList
                    search={search}
                    status={status}
                    date={date}
                    paging={paging}
                    onChangePage={handleChangePaging}
                />
            </StyledSchedule>
        </>
    );
};

export default Schedule;
