import { useSelector } from "react-redux";

import { Edit, PersonOutline } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { generateAge } from "../../utils/date";

const CustomerInfo = () => {
    const checkList = useSelector((state) => state.checkList);
    return (
        <Box sx={{ flexGrow: 1 }} className="personal-info">
            <Stack direction="row" spacing={1} alignItems="center" className="title">
                <PersonOutline fontSize="large" />
                <Typography variant="h6">Thông tin cá nhân</Typography>
            </Stack>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={5}>
                    Họ và tên:
                </Grid>
                <Grid item xs={7}>
                    <Typography fontWeight={600} color="primary" sx={{ cursor: "pointer" }}>
                        {checkList?.customer?.name}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    Ngày sinh
                </Grid>
                <Grid item xs={7}>
                    <Typography fontWeight={600}>{checkList?.customer?.dateOfBirth}</Typography>
                </Grid>
                <Grid item xs={5}>
                    Tuổi
                </Grid>
                <Grid item xs={7}>
                    <Typography fontWeight={600}>{generateAge(checkList?.customer?.dateOfBirth)}</Typography>
                </Grid>
                <Grid item xs={5}>
                    Mã tiêm chủng:
                </Grid>
                <Grid item xs={7}>
                    <Typography fontWeight={600}>{checkList?.customer?.id}</Typography>
                </Grid>
                <Grid item xs={5}>
                    Ghi chú:
                </Grid>
                <Grid item xs={7}>
                    {checkList?.note && <Typography>{checkList?.note}</Typography>}
                    <Button variant="text" startIcon={<Edit fontSize="small" />} size="small">
                        Thêm ghi chú
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerInfo;
