import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";

import LOGO from "../../../assets/images/logo-nutivac.jpg";
import { surveyChildContents, childNotes } from "../config";

const ChildExaminationSheet = React.forwardRef(({ examination, vaccines }, ref) => {
    const [vaccinesInfo, setVaccinesInfo] = useState([]);
    const checkList = useSelector((state) => state.checkList);
    const vaccineList = useSelector((state) => state.vaccine);

    useEffect(() => {
        const info = vaccines.reduce((acc, vaccine, index) => {
            const vaccineInfo = vaccineList.find((item) => item.id === vaccine.vaccineId);
            if (!vaccineInfo) return acc;
            return [
                ...acc,
                `${index + 1}. ${vaccineInfo.name} - ${vaccineInfo.desc} - Mũi ${vaccine.numberOfInjections} - ${
                    vaccineInfo.method
                } - ${vaccineInfo.origin}`,
            ];
        }, []);
        setVaccinesInfo(info);
    }, [vaccines, vaccineList]);
    return (
        <>
            <div ref={ref} style={{ width: "100%", heigh: window.innerHeight }}>
                <Box sx={{ paddingX: 10, paddingY: 6 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <img src={LOGO} alt="no data" width="60" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography
                                    align="center"
                                    fontWeight={700}
                                    fontSize={18}
                                    sx={{ textTransform: "uppercase" }}
                                >
                                    PHÒNG TIÊM CHỦNG VẮC XIN {checkList?.store?.name}
                                </Typography>
                                <Typography align="center">Địa chỉ: {checkList?.store?.address}</Typography>
                                <Typography align="center">Hotline: {checkList?.store?.hotline}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography align="center" fontWeight={700} fontSize={20} mb={3}>
                        BẢNG KIỂM TRƯỚC TIÊM CHỦNG
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                Họ và tên:
                            </Grid>
                            <Grid item xs={5}>
                                <Typography fontWeight={500} textTransform="uppercase">
                                    {checkList?.customer?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                Ngày sinh:
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>{checkList?.customer?.dateOfBirth}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Địa chỉ:
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{checkList?.customer?.address}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Họ và tên bố/mẹ:
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>{checkList?.customer?.parentName}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                Số điện thoại:
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>{checkList?.customer?.phoneNumber}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography fontWeight={500} mb={1} fontSize={16}>
                        Kết quả khám:
                    </Typography>
                    <Box mb={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                Cân nặng:{" "}
                                {examination.weight === "" ? (
                                    ".............."
                                ) : (
                                    <span style={{ fontWeight: 500 }}>{examination.weight}</span>
                                )}{" "}
                                kg
                            </Grid>
                            <Grid item xs={4}>
                                Chiều cao:{" "}
                                {examination.height === "" ? (
                                    ".............."
                                ) : (
                                    <span style={{ fontWeight: 500 }}>{examination.height}</span>
                                )}{" "}
                                cm
                            </Grid>
                            <Grid item xs={4}>
                                Thân nhiệt:{" "}
                                {examination.hypothermia === "" ? (
                                    ".............."
                                ) : (
                                    <span style={{ fontWeight: 500 }}>{examination.hypothermia}</span>
                                )}{" "}
                                độ C
                            </Grid>
                            <Grid item xs={4}>
                                Huyết áp:{" "}
                                {examination.bloodPressure === "" ? (
                                    ".............."
                                ) : (
                                    <span style={{ fontWeight: 500 }}>{examination.bloodPressure}</span>
                                )}{" "}
                                mmHg
                            </Grid>
                            <Grid item xs={4}>
                                Nhịp tim:{" "}
                                {examination.heartbeat === "" ? (
                                    ".............."
                                ) : (
                                    <span style={{ fontWeight: 500 }}>{examination.heartbeat}</span>
                                )}{" "}
                                lần/phút
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} mb={2}>
                        {surveyChildContents.map((content, index) => (
                            <Grid container spacing={3} alignItems="center" key={uuidv4()}>
                                <Grid item xs={11}>
                                    {content}
                                </Grid>
                                <Grid item xs={1}>
                                    <Stack direction="row" spacing={1} alignItems="end">
                                        {examination.survey[index] ? (
                                            <CheckBoxOutlined fontSize="large" />
                                        ) : (
                                            <CheckBoxOutlineBlankOutlined fontSize="large" />
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                    <Typography fontWeight={500} mb={1} fontSize={16}>
                        Kết luận:
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography>Đủ điều kiện tiêm chủng ngay (Tất cả đều Không có điểm bất thường)</Typography>
                        {examination.conclusion ? (
                            <CheckBoxOutlined fontSize="large" />
                        ) : (
                            <CheckBoxOutlineBlankOutlined fontSize="large" />
                        )}
                    </Stack>
                    <Box mb={2}>
                        <Typography>Vắc xin tiêm chủng lần này:</Typography>
                        {vaccinesInfo.map((vaccineInfo, index) => (
                            <Typography key={index} fontWeight={500} pl={2}>
                                {vaccineInfo}
                            </Typography>
                        ))}
                    </Box>
                    {childNotes.map((note, index) => (
                        <Stack key={uuidv4()} direction="row" justifyContent="space-between" alignItems="center">
                            <Typography>{note}</Typography>
                            {examination.notes[index] ? (
                                <CheckBoxOutlined fontSize="large" />
                            ) : (
                                <CheckBoxOutlineBlankOutlined fontSize="large" />
                            )}
                        </Stack>
                    ))}
                    <Box sx={{ flexGrow: 1 }} mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography textAlign="center" fontStyle="italic" fontWeight={500}>
                                    Ngày {dayjs().format("DD/MM/YYYY HH:mm")}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography textAlign="center" fontWeight={500}>
                                    Người thực hiện khám sàng lọc
                                </Typography>
                                <Typography textAlign="center" fontStyle="italic">
                                    (Ký, ghi rõ họ và tên)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    );
});

export default ChildExaminationSheet;
