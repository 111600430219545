import React from "react";
import { Box, Typography } from "@mui/material";

const NoData = () => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography textAlign="center" paddingY={3}>
                Không có dữ liệu để hiển thị
            </Typography>
        </Box>
    );
};

export default NoData;
