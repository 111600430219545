const GENDER = [
    {
        key: 2,
        value: "Tất cả",
    },
    {
        key: 0,
        value: "Nam",
    },
    {
        key: 1,
        value: "Nữ",
    },
];

const GENDER_VALUE_DEFAULT = 2;

const INJECTION_TYPE = [
    {
        key: "ODD_INJECTION",
        value: "Tiêm lẻ",
    },
    // {
    //     key: "PACK_INJECTION",
    //     value: "Tiêm gói",
    // },
];

const INJECTION_TYPE_DEFAULT = "ODD_INJECTION";

export { GENDER, GENDER_VALUE_DEFAULT, INJECTION_TYPE, INJECTION_TYPE_DEFAULT };
