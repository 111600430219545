import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Layout from "../containers/Layout";
import appRoutes from "./appRoutes";
import ROUTE from "../constants/route";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import axiosClient from "../apis/api";
import apis from "../apis";
import { setVaccines } from "../stores/vaccineSlice";
import { handleCallApiError } from "../errors";
import { setCurrentUser } from "../stores/userSlice";
import { setCurrentStore, setStoreActive, setStores } from "../stores/storeSlice";
import { Backdrop, CircularProgress } from "@mui/material";
import { ALL_STORE } from "../constants";
import { VACCINE_STATUS } from "../constants/vaccines";
import { setCurrentRole, setPermissions } from "../stores/roleSlice";
import { PERMISSION_TYPE } from "../constants/permission";

const PrivateApp = () => {
    const [loading, setLoading] = useState(false);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const currentStore = useSelector((state) => state.store.currentStore);
    const { currentRole, permissions } = useSelector((state) => state.role);
    const privateRoutes = appRoutes.filter((route) => route.isPrivate);
    (() => {
        axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    })();

    const dispatch = useDispatch();

    const init = async () => {
        setLoading(true);
        await Promise.all([getMe(), getStores()]);
        setLoading(false);
    };

    const getStores = async () => {
        try {
            const res = await apis.store.getStores({});
            dispatch(setStores(res.stores));
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const getMe = async () => {
        try {
            const res = await apis.auth.getMe();
            dispatch(setCurrentUser(res.me));
            dispatch(setCurrentStore(res.me.storeId));
            dispatch(setCurrentRole(res.me.roleId));
            await getRole(res.me.roleId);
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const getStore = async (storeId) => {
        try {
            const { store } = await apis.store.getStore(storeId);
            dispatch(setStoreActive(store.status));
            const vaccines = store.vaccines
                .filter((vaccine) => vaccine.status === VACCINE_STATUS.ACTIVE)
                .map(({ detail, ...vaccineInfo }) => {
                    delete detail.storeId;
                    delete detail.vaccineId;
                    return { ...detail, ...vaccineInfo };
                });
            dispatch(setVaccines(vaccines));
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const getRole = async (roleId) => {
        try {
            const {
                role: { permissions = [] },
            } = await apis.role.getRole(roleId);
            const menuPermissions = permissions
                .filter((permission) => permission.type === PERMISSION_TYPE.MENU)
                .map((permission) => permission.path);
            dispatch(setPermissions(menuPermissions));
        } catch (error) {
            handleCallApiError(error);
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (currentStore !== ALL_STORE) getStore(currentStore);
    }, [currentStore]);

    useEffect(() => {
        if (currentRole) getRole(currentRole);
    }, [currentRole]);

    if (loading) {
        return (
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#ffffff" }} open={loading}>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <Layout>
            <Switch>
                {privateRoutes
                    .filter((privateRoute) => permissions.includes(privateRoute.path))
                    .map((privateRoute) => (
                        <PrivateRoute
                            path={privateRoute.path}
                            component={privateRoute.component}
                            exact
                            key={privateRoute.path}
                        />
                    ))}
                <Route render={() => <Redirect to={ROUTE.DASHBOARD} />} />
            </Switch>
        </Layout>
    );
};

const AppRouter = () => {
    const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

    return (
        <BrowserRouter>
            <Switch>
                {publicRoutes.map((publicRoute) => (
                    <PublicRoute
                        exact
                        path={publicRoute.path}
                        component={publicRoute.component}
                        restricted={publicRoute.restricted}
                        key={publicRoute.path}
                    />
                ))}

                <PrivateRoute component={PrivateApp} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;
