import Examination from "./Examination";
import Schedule from "./Schedule";

const tabs = [
    {
        name: "Khám sàng lọc",
        value: "1",
        component: <Examination />,
    },
    {
        name: "Hẹn tiêm",
        value: "2",
        component: <Schedule />,
    },
];

export const surveyChildContents = [
    "1. Sốc, phản ứng nặng sau lần tiêm chủng trước",
    "2. Đang mắc bệnh cấp tính hoặc bệnh mãn tính tiến triển",
    "3. Đang hoặc mới kết thúc liều điều trị corticoid/gammaglobumin",
    "4. Sốt/Hạ thân nhiệt (Sốt: nhiệt độ ≥ 37,5 độ C ; Hạ thân nhiệt: nhiệt độ ≤ 35,5 độ C)",
    "5. Nghe tim bất thường",
    "6. Nhịp thở, nghe phổi bất thường",
    "7. Tri giác bất thường (li bi hoặc kích thích)",
    "8. Có các chống chỉ định khác",
];

export const surveyAdultContents = [
    "1. Tiền sử rõ ràng phản vệ với lần tiêm chủng trước",
    "2. Đang mắc bệnh cấp tính",
    "3. Phản vệ độ 3 trở lên với bất kỳ dị nguyên nào",
    "4. Đang bị suy giảm miễn dịch nặng, ung thư giai đoạn cuối đang điều trị hóa trị, xạ trị",
    "5. Tiền sử dị ứng với bất kỳ dị nguyên nào",
    "6. Tiền sử rối loạn đông máu, cầm máu",
    "7. Rối loạn tri giác, hành vi",
    "8. Phụ nữ mang thai",
];

export const childNotes = [
    "Chống chỉ định tiêm chủng (Khi có điểm bất thường tại mục 1,8)",
    "Tạm hoãn tiêm chủng (Khi có bất kỳ một điểm bất thường tại các mục 2,3,4,5,6,7)",
];

export const adultNotes = [
    "Chống chỉ định tiêm chủng (Khi có điểm bất thường tại mục 1)",
    "Tạm hoãn tiêm chủng (Khi có bất kỳ một điểm bất thường tại các mục 2,8)",
    "Chỉ định tiêm tại các CSYT có đủ điều kiện cấp cứu phản vệ (Khi có bất thường tại mục 3)",
    "Nhóm thận trọng khi tiêm chủng (Khi có bất kỳ 1 điểm bất thường tại mục 4,5,6,7)",
];

export default tabs;
