import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import Table from "../../../components/Table";
import apis from "../../../apis";
import debounce from "../../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../../constants";
import { CHECK_LIST_STATUS } from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import { handleCallApiError } from "../../../errors";
import DeleteCheckListDialog from "../../DeleteCheckListDialog";
import ExaminationDialog from "../../ExaminationDialog";
import { setCurrentCheckList } from "../../../stores/checkListSlice";
import { renderStatus } from "../../../utils/checklist";

const ExaminationList = ({ search, paging, onChangePage, setReload }) => {
    const [checkLists, setCheckLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [checkList, setCheckList] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [examinationDialog, setExaminationDialog] = useState(false);
    const [stop, setStop] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);

    const dispatch = useDispatch();

    const handleCloseExaminationDialog = () => {
        dispatch(setCurrentCheckList({}));
        setExaminationDialog(false);
        setStop(false);
        fetchCheckLists();
    };

    const handleOpenExaminationDialog = (row) => {
        dispatch(setCurrentCheckList(row));
        setExaminationDialog(true);
        setStop(true);
    };

    const getCheckLists = async () => {
        setReload(true);
        try {
            const condition = {
                search,
                status: CHECK_LIST_STATUS.WAITING_FOR_EXAMINATION,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCheckLists(condition);
            setCheckLists(data?.checkLists || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setReload(false);
    };

    const fetchCheckLists = async () => {
        setLoading(true);
        await getCheckLists();
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleChangeConfirmDeleteDialog = (row = {}) => {
        setCheckList(row);
        setConfirmDeleteDialog((prev) => !prev);
    };

    const handleDelete = async () => {
        try {
            setWaiting(true);
            await apis.checkList.deleteCheckList(checkList.id);
            const customerName = checkList.customer.name;
            setCheckList({});
            setWaiting(false);
            setConfirmDeleteDialog(false);
            handleChangePage(0);
            toast.success(`Phiếu đăng ký của ${customerName} đã bị xóa`);
        } catch (error) {
            setWaiting(false);
            handleCallApiError(error);
        }
    };

    const columns = [
        {
            title: "Trạng thái",
            align: "left",
            render: (row) => renderStatus(row.status),
        },
        {
            field: "order",
            title: "Số phiếu",
            align: "center",
        },
        {
            title: "Thời gian đăng ký",
            align: "center",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Mã tiêm chủng",
            align: "left",
            render: (row) => row.customer.id,
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <Typography color="primary" fontSize="13px">
                    {row.customer.name}
                </Typography>
            ),
        },
        {
            title: "Ngày sinh",
            align: "left",
            render: (row) => row.customer.dateOfBirth,
        },
        {
            title: "Vắc xin chỉ định",
            align: "left",
            render: (row) => (
                <>
                    {row.vaccines.map((vaccine, index) => (
                        <Typography fontSize="13px">{`${index + 1}. ${vaccine.name} | ${vaccine.desc}`}</Typography>
                    ))}
                </>
            ),
        },
        {
            field: "note",
            title: "Ghi chú",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Box display="flex" justifyContent="center">
                    <Tooltip title="Khám, chỉ định, hẹn tiêm">
                        <IconButton onClick={() => handleOpenExaminationDialog(row)}>
                            <AddCircleOutline sx={{ color: COLOR.primary }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Xóa phiếu">
                        <IconButton onClick={() => handleChangeConfirmDeleteDialog(row)}>
                            <Delete sx={{ color: COLOR.grey }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchCheckLists, 500)(search);
    }, [paging.page, search, currentStore]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!stop) getCheckLists();
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, [search, stop]);

    return (
        <>
            <Table
                data={checkLists}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <DeleteCheckListDialog
                checkList={checkList}
                confirmDeleteDialog={confirmDeleteDialog}
                handleChangeConfirmDeleteDialog={handleChangeConfirmDeleteDialog}
                handleDelete={handleDelete}
                waiting={waiting}
            />
            <ExaminationDialog open={examinationDialog} handleClose={handleCloseExaminationDialog} />
        </>
    );
};

export default ExaminationList;
