import React, { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";

import { StyledUser } from "./index.style";
import UserList from "./UserList";
import { INIT_USER } from "../../constants/user";
import UserDialog from "./UserDialog";

const INITIAL_PAGING = { page: 1, total: 0 };

const User = () => {
    const [search, setSearch] = useState("");
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [userDialog, setUserDialog] = useState(false);
    const [user, setUser] = useState({});
    const [reload, setReload] = useState(false);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleOpenUserDialog = () => {
        setUser(INIT_USER);
        setUserDialog(true);
    };

    const handleCloseUserDialog = () => {
        setUserDialog(false);
    };

    const handleReload = () => {
        setReload((prev) => !prev);
    };

    return (
        <>
            <StyledUser>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm nhân sự theo tên, email hoặc số điện thoại"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            color="success"
                            sx={{ paddingTop: "7px", paddingBottom: "6.5px", marginRight: "5px" }}
                            onClick={handleOpenUserDialog}
                        >
                            Thêm nhân sự
                        </Button>
                    </Stack>
                </div>
                <UserList search={search} paging={paging} onChangePage={handleChangePaging} reload={reload} />
            </StyledUser>
            <UserDialog
                open={userDialog}
                handleClose={handleCloseUserDialog}
                user={user}
                setUser={setUser}
                handleReload={handleReload}
                dialogType="create"
            />
        </>
    );
};

export default User;
