import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { USER_STATUS } from "../../constants/user";
import { COLOR } from "../../styles/color";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import UserDialog from "./UserDialog";
import { ALL_STORE, PAGINATION_LIMIT } from "../../constants";

const UserList = ({ search, paging, onChangePage, reload }) => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [userDialog, setUserDialog] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleOpenUserDialog = (row) => {
        setUser(row);
        setUserDialog(true);
    };

    const handleCloseUserDialog = () => {
        setUserDialog(false);
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const condition = {
                search,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const res = await apis.user.getUsers(condition);
            setUsers(res.users);
            onChangePage({ ...paging, total: res?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const renderStatus = (status) => {
        switch (status) {
            case USER_STATUS.ACTIVE:
                return (
                    <Typography sx={{ color: COLOR.success }} fontSize="13px">
                        Hoạt động
                    </Typography>
                );

            case USER_STATUS.INACTIVE:
                return (
                    <Typography sx={{ color: COLOR.danger }} fontSize="13px">
                        Không hoạt động
                    </Typography>
                );

            default:
                break;
        }
    };

    const columns = [
        {
            field: "name",
            title: "Họ và tên",
            align: "left",
        },
        {
            field: "email",
            title: "Email",
            align: "left",
        },
        {
            field: "phoneNumber",
            title: "SĐT",
            align: "left",
        },
        {
            field: "dateOfBirth",
            title: "Ngày sinh",
            align: "left",
        },
        {
            title: "Vai trò",
            align: "left",
            render: (row) => row.role.name,
        },
        {
            title: "Trạng thái",
            align: "center",
            render: (row) => renderStatus(row.status),
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip title="Chỉnh sửa">
                        <IconButton onClick={() => handleOpenUserDialog(row)}>
                            <Edit sx={{ color: COLOR.purple }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchUsers, 500)(search);
    }, [paging.page, search, reload, currentStore]);

    return (
        <>
            <Table
                data={users}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
                showNumber={true}
            />
            <UserDialog
                open={userDialog}
                handleClose={handleCloseUserDialog}
                user={user}
                setUser={setUser}
                handleReload={fetchUsers}
                dialogType="update"
            />
        </>
    );
};

export default UserList;
