import React, { useState } from "react";
import { Schedule, InfoOutlined } from "@mui/icons-material";
import { Box, DialogContent, Divider, Grid, Stack, Typography } from "@mui/material";

import Dialog from "../../../components/Dialog";
import { generateAge } from "../../../utils/date";
import History from "../../ExaminationDialog/History";
import AppointmentsHistory from "../../ExaminationDialog/Schedule/AppointmentsHistory";

const INITIAL_PAGING = { page: 1, total: 0 };

const CustomerDetail = ({ open, handleClose, customer }) => {
    const [paging, setPaging] = useState(INITIAL_PAGING);

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    return (
        <Dialog title="Thông tin khách hàng" maxWidth="md" open={open} onClose={handleClose}>
            <DialogContent>
                <Stack direction="row" spacing={1} alignItems="center" className="title" mb={2}>
                    <InfoOutlined fontSize="large" />
                    <Typography variant="h6">Thông tin chi tiết</Typography>
                </Stack>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Họ và tên:
                            </Typography>
                            <Typography>{customer?.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Giới tính:
                            </Typography>
                            <Typography>{!customer?.gender ? "Nam" : "Nữ"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Ngày sinh:
                            </Typography>
                            <Typography>{customer?.dateOfBirth}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Tuổi:
                            </Typography>
                            <Typography>{generateAge(customer?.dateOfBirth)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Phụ huynh:
                            </Typography>
                            <Typography>{customer?.parentName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                        <Box display="flex">
                            <Typography fontWeight={500} marginRight={1}>
                                Số điện thoại:
                            </Typography>
                            <Typography>{customer?.phoneNumber}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 3 }} />
                <Stack direction="row" spacing={1} alignItems="center" className="title" mb={2}>
                    <Schedule fontSize="large" />
                    <Typography variant="h6">Lịch hẹn tiêm</Typography>
                </Stack>
                <AppointmentsHistory paging={paging} onChangePage={handleChangePaging} customerId={customer?.id} />
                <Divider sx={{ marginY: 3 }} />
                <History />
            </DialogContent>
        </Dialog>
    );
};

export default CustomerDetail;
