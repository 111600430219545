const PAYMENT_TYPE = {
    CASH: "cash",
    TRANSFER: "transfer",
};

const READABLE_PAYMENT_TYPE = {
    [PAYMENT_TYPE.CASH]: "Tiền mặt",
    [PAYMENT_TYPE.TRANSFER]: "Chuyển khoản",
};

export { PAYMENT_TYPE, READABLE_PAYMENT_TYPE };
