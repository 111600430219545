import Customers from "./Customers";
import Registered from "./Registered";
import Schedule from "./Schedule";
import Cashier from "./Cashier";

const tabs = [
    {
        name: "Danh sách",
        value: "1",
        component: <Customers />,
    },
    {
        name: "Thu ngân",
        value: "2",
        component: <Cashier />,
    },
    {
        name: "Lịch hẹn tiêm",
        value: "3",
        component: <Schedule />,
    },
    {
        name: "Đã đăng ký",
        value: "4",
        component: <Registered />,
    },
];

export default tabs;
