import api from "./api";

const getUsers = async ({ search, limit, offset, storeId }) => {
    const response = await api({
        method: "GET",
        url: "/users",
        params: { search, limit, offset, storeId },
    });

    return response;
};

const createUser = async (data) => {
    const response = await api({
        method: "POST",
        url: "/users",
        data,
    });

    return response;
};

const updateUser = async (userId, data) => {
    const response = await api({
        method: "PUT",
        url: `/users/${userId}`,
        data,
    });

    return response;
};

export { getUsers, createUser, updateUser };
