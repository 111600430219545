import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";

import Table from "../../../components/Table";
import apis from "../../../apis";
import debounce from "../../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../../constants";
import { CHECK_LIST_STATUS, CHECK_LIST_STATUS_DEFAULT } from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import { setCurrentCheckList } from "../../../stores/checkListSlice";
import ExaminationDialog from "../../ExaminationDialog";
import { renderStatus } from "../../../utils/checklist";
import { handleCallApiError } from "../../../errors";

const ALL_CHECK_LIST = `${CHECK_LIST_STATUS.WAITING_FOR_PAYMENT},${CHECK_LIST_STATUS.WAITING_FOR_INJECTION},${CHECK_LIST_STATUS.WAITING_FOR_REACTION},${CHECK_LIST_STATUS.DONE},${CHECK_LIST_STATUS.CANCEL},${CHECK_LIST_STATUS.DO_NOT_INJECT}`;

const MedicalHistory = ({ search, status, paging, onChangePage }) => {
    const [checkLists, setCheckLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [examinationDialog, setExaminationDialog] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);
    const dispatch = useDispatch();

    const fetchCheckLists = async () => {
        setLoading(true);
        try {
            const condition = {
                search,
                status: status === CHECK_LIST_STATUS_DEFAULT ? ALL_CHECK_LIST : status,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCheckLists(condition);
            setCheckLists(data?.checkLists || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleChangeExaminationDialog = (row) => {
        dispatch(setCurrentCheckList(row));
        setExaminationDialog((prev) => !prev);
        if (examinationDialog) {
            fetchCheckLists();
        }
    };

    const columns = [
        {
            field: "status",
            title: "Trạng thái",
            align: "left",
            render: (row) => renderStatus(row.status),
        },
        {
            title: "Thời gian đăng ký",
            align: "center",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <>
                    <Typography color="primary" fontSize="13px">
                        {row.customer.name}
                    </Typography>
                    <Typography fontSize="13px">{row.customer.id}</Typography>
                </>
            ),
        },
        {
            title: "Ngày sinh",
            align: "left",
            render: (row) => row.customer.dateOfBirth,
        },
        {
            title: "Vắc xin chỉ định",
            align: "left",
            render: (row) => (
                <>
                    {row.vaccines.map((vaccine, index) => (
                        <Typography fontSize="13px">{`${index + 1}. ${vaccine.name} | ${vaccine.desc}`}</Typography>
                    ))}
                </>
            ),
        },
        {
            field: "note",
            title: "Ghi chú",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) =>
                row.status === CHECK_LIST_STATUS.WAITING_FOR_PAYMENT && (
                    <Tooltip title="Chỉnh sửa">
                        <IconButton onClick={() => handleChangeExaminationDialog(row)}>
                            <Edit sx={{ color: COLOR.grey }} />
                        </IconButton>
                    </Tooltip>
                ),
        },
    ];

    useEffect(() => {
        debounce(fetchCheckLists, 500)(search);
    }, [paging.page, search, status, currentStore]);

    return (
        <>
            <Table
                data={checkLists}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <ExaminationDialog open={examinationDialog} handleClose={handleChangeExaminationDialog} />
        </>
    );
};

export default MedicalHistory;
