const STORE_STATUS = {
    ACTIVE: "active",
    INACTIVE: "inactive",
};

const INIT_STORE_ERROR = {
    name: false,
    hotline: false,
    address: false,
};

const INIT_STORE = {
    name: "",
    hotline: "",
    address: "",
};

export { STORE_STATUS, INIT_STORE_ERROR, INIT_STORE };
