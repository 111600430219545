import api from "./api";

const getVaccines = async ({ storeId, search, method, origin, limit, offset }) => {
    const response = await api({
        method: "GET",
        url: "/warehouse/vaccines",
        params: { storeId, search, method, origin, limit, offset },
    });

    return response;
};

const updateVaccine = async (data) => {
    const response = await api({
        method: "PUT",
        url: `/warehouse/vaccines`,
        data,
    });

    return response;
};

export { getVaccines, updateVaccine };
