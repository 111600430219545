import { Typography } from "@mui/material";

import { checkListStatus, CHECK_LIST_STATUS } from "../constants/checkList";
import { COLOR } from "../styles/color";
import { formatMoney } from "./money";

const renderStatus = (status) => {
    const statusObject = checkListStatus.find((s) => s.value === status);
    if (!statusObject) return;
    switch (statusObject.value) {
        case CHECK_LIST_STATUS.DONE:
            return (
                <Typography color="primary" fontSize="14px">
                    {statusObject.label}
                </Typography>
            );
        case CHECK_LIST_STATUS.DO_NOT_INJECT:
            return (
                <Typography sx={{ color: COLOR.purple }} fontSize="14px">
                    {statusObject.label}
                </Typography>
            );
        case CHECK_LIST_STATUS.CANCEL:
            return (
                <Typography sx={{ color: COLOR.grey }} fontSize="14px">
                    {statusObject.label}
                </Typography>
            );
        default:
            return (
                <Typography sx={{ color: COLOR.red }} fontSize="14px">
                    {statusObject.label}
                </Typography>
            );
    }
};

const renderTotalPrice = (vaccines = []) => {
    return `${formatMoney(
        vaccines.reduce((total, vaccine) => {
            return total + (vaccine.detail.price - vaccine.detail.discount);
        }, 0)
    )} VNĐ`;
};

export { renderStatus, renderTotalPrice };
