import React from "react";
import { Box, Button, DialogActions, DialogContent, Divider, Grid, TextField, Typography } from "@mui/material";

import Dialog from "../../../components/Dialog";
import { renderStatus } from "../../../utils/checklist";
import { formatMoney } from "../../../utils/money";
import { LoadingButton } from "@mui/lab";

const ReactDialog = ({
    checkList,
    reactDialog,
    handleCloseReactDialog,
    handleUpdateReaction,
    reaction,
    setReaction,
    waiting,
}) => {
    const handleChangeReaction = (event) => {
        setReaction(event.target.value);
    };

    return (
        <Dialog title="Cập nhật phản ứng sau tiêm" open={reactDialog} onClose={handleCloseReactDialog}>
            <DialogContent sx={{ padding: "10px 15px" }}>
                <Typography marginBottom={2}>Thông tin thanh toán</Typography>
                <Box mb={3} marginX={3}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight={500} mr={1}>
                                    Trạng thái:
                                </Typography>
                                {renderStatus(checkList?.status)}
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                            <Box display="flex">
                                <Typography fontWeight={500} mr={1}>
                                    Họ và tên:
                                </Typography>
                                <Typography>{checkList.customer?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box display="flex" alignItems="flex-start">
                                <Typography fontWeight={500} mr={2}>
                                    Vắc xin chỉ định:
                                </Typography>
                                <Box>
                                    {checkList.vaccines?.map((vaccine, index) => (
                                        <Typography key={index} mb={1}>{`${index + 1}. ${vaccine.name} | ${
                                            vaccine.desc
                                        } | ${vaccine.origin} | ${formatMoney(
                                            vaccine.detail.price - vaccine.detail.discount
                                        )} VNĐ`}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={12}>
                            <Box>
                                <Typography fontWeight={500} marginBottom={1}>
                                    Phản ứng sau tiêm:
                                </Typography>
                                <TextField
                                    value={reaction}
                                    size="small"
                                    fullWidth
                                    multiline={true}
                                    onChange={handleChangeReaction}
                                    rows={3}
                                    placeholder="Không có"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseReactDialog}>
                    Hủy
                </Button>
                <LoadingButton variant="contained" onClick={handleUpdateReaction} loading={waiting}>
                    Cập nhật
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ReactDialog;
