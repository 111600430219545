import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const checkListSlice = createSlice({
    name: "checkList",
    initialState: initialState,
    reducers: {
        setCurrentCheckList: (state, action) => {
            return { ...action.payload };
        },
    },
});

export const { setCurrentCheckList } = checkListSlice.actions;

export default checkListSlice.reducer;
