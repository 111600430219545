import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { HighlightOff, Payment } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../../components/Table";
import apis from "../../../apis";
import debounce from "../../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../../constants";
import { CHECK_LIST_STATUS } from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import { handleCallApiError } from "../../../errors";
import { renderStatus } from "../../../utils/checklist";
import { formatMoney } from "../../../utils/money";
import ReceiptSheet from "./ReceiptSheet";
import CancelPaymentDialog from "./CancelPaymentDialog";
import { PAYMENT_TYPE } from "../../../constants/payment";
import ConfirmPaymentDialog from "./ConfirmPaymentDialog";

const PaymentList = ({ search, paging, onChangePage, setReload }) => {
    const [checkLists, setCheckLists] = useState([]);
    const [checkList, setCheckList] = useState({});
    const [loading, setLoading] = useState(false);
    const [confirmCancelDialog, setConfirmCancelDialog] = useState(false);
    const [confirmPaymentDialog, setConfirmPaymentDialog] = useState(false);
    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CASH);
    const [waiting, setWaiting] = useState(false);
    const componentRef = useRef();
    const currentStore = useSelector((state) => state.store.currentStore);

    const getCheckLists = async () => {
        setReload(true);
        try {
            const condition = {
                search,
                status: CHECK_LIST_STATUS.WAITING_FOR_PAYMENT,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCheckLists(condition);
            setCheckLists(data?.checkLists || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setReload(false);
    };

    const fetchCheckLists = async () => {
        setLoading(true);
        await getCheckLists();
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const renderTotalPrice = (vaccines = []) => {
        return `${formatMoney(
            vaccines.reduce((total, vaccine) => {
                return total + (vaccine.detail.price - vaccine.detail.discount);
            }, 0)
        )} VNĐ`;
    };

    const handleOpenCancelDialog = (row) => {
        setCheckList(row);
        setConfirmCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setCheckList({});
        setConfirmCancelDialog(false);
    };

    const handleOpenConfirmDialog = (row) => {
        setCheckList(row);
        setConfirmPaymentDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setCheckList({});
        setPaymentType(PAYMENT_TYPE.CASH);
        setConfirmPaymentDialog(false);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `hoa_don_thanh_toan_${checkList.id}`,
        onAfterPrint: () => {
            setCheckList({});
            setPaymentType(PAYMENT_TYPE.CASH);
        },
    });

    const handleCancelPayment = async () => {
        setWaiting(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, { status: CHECK_LIST_STATUS.CANCEL });
            handleCloseCancelDialog();
            toast.success("Hủy thanh toán thành công");
            fetchCheckLists();
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleConfirmPayment = async () => {
        setWaiting(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, {
                status: CHECK_LIST_STATUS.WAITING_FOR_INJECTION,
                paymentType,
            });
            setConfirmPaymentDialog(false);
            handlePrint();
            toast.success("Lưu thanh toán thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const columns = [
        {
            title: "Trạng thái",
            align: "left",
            render: (row) => renderStatus(row.status),
        },
        {
            title: "Thời gian đăng ký",
            align: "center",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <>
                    <Typography color="primary" fontSize="13px">
                        {row.customer.name}
                    </Typography>
                    <Typography fontSize="13px">{row.customer.id}</Typography>
                </>
            ),
        },
        {
            title: "Vắc xin chỉ định",
            align: "left",
            render: (row) => (
                <>
                    {row.vaccines.map((vaccine, index) => (
                        <Typography fontSize="13px">{`${index + 1}. ${vaccine.name} | ${vaccine.desc}`}</Typography>
                    ))}
                </>
            ),
        },
        {
            title: "Thành tiền",
            align: "center",
            render: (row) => renderTotalPrice(row.vaccines),
        },
        {
            field: "note",
            title: "Ghi chú",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Stack direction="row" justifyContent="center">
                    <Tooltip title="Thanh toán">
                        <IconButton onClick={() => handleOpenConfirmDialog(row)}>
                            <Payment sx={{ color: COLOR.primary }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Hủy">
                        <IconButton onClick={() => handleOpenCancelDialog(row)}>
                            <HighlightOff sx={{ color: COLOR.grey }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchCheckLists, 500)(search);
    }, [paging.page, search, currentStore]);

    useEffect(() => {
        const interval = setInterval(() => {
            getCheckLists();
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, [search]);

    return (
        <>
            <Table
                data={checkLists}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <CancelPaymentDialog
                checkList={checkList}
                confirmCancelDialog={confirmCancelDialog}
                handleCloseCancelDialog={handleCloseCancelDialog}
                handleCancelPayment={handleCancelPayment}
                waiting={waiting}
            />
            <ConfirmPaymentDialog
                checkList={checkList}
                confirmPaymentDialog={confirmPaymentDialog}
                handleCloseConfirmDialog={handleCloseConfirmDialog}
                handleConfirmPayment={handleConfirmPayment}
                waiting={waiting}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
            />
            <div style={{ display: "none" }}>
                <ReceiptSheet ref={componentRef} checkList={checkList} paymentType={paymentType} />
            </div>
        </>
    );
};

export default PaymentList;
