import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

import { StyledRoleList } from "./index.style";
import Dialog from "../../components/Dialog";
import { LoadingButton } from "@mui/lab";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";

const INT_ROLE_ERROR = {
    name: false,
    key: false,
};

const INT_ROLE = {
    name: "",
    key: "",
};

const RoleList = ({ selectedRole, setSelectedRole, reload }) => {
    const [errorRole, setErrorRole] = useState(INT_ROLE_ERROR);
    const [openDialog, setOpenDialog] = useState(false);
    const [role, setRole] = useState(INT_ROLE);
    const [dialogType, setDialogType] = useState("create");
    const [loading, setLoading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [roles, setRoles] = useState([]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setErrorRole(INT_ROLE_ERROR);
        setRole(INT_ROLE);
    };

    const handleOpenDialog = (type, object) => {
        setDialogType(type);
        if (object) setRole(object);
        setOpenDialog(true);
    };

    const handChangeRole = (event, field) => {
        const value = event.target.value;
        setRole((prev) => ({ ...prev, [field]: value }));
        setErrorRole((prev) => ({ ...prev, [field]: false }));
    };

    const getRoles = async () => {
        try {
            const res = await apis.role.getRoles();
            setRoles(res.roles);
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const fetchRoles = async () => {
        setLoading(true);
        await getRoles();
        setLoading(false);
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...errorRole };
        Object.keys(INT_ROLE_ERROR).forEach((key) => {
            if (role[key] === INT_ROLE[key]) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setErrorRole(error);
        return isSuccess;
    };

    const handleCreateRole = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.role.createRole(role);
            handleCloseDialog();
            getRoles();
            toast.success("Thêm vai trò thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleUpdateRole = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.role.updateRole(role.id, role);
            handleCloseDialog();
            getRoles();
            toast.success("Cập nhật vai trò thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleDeleteRole = async (roleId) => {
        try {
            await apis.role.deleteRole(roleId);
            if (selectedRole?.id === roleId) setSelectedRole(null);
            handleCloseDialog();
            getRoles();
            toast.success("Xóa vai trò thành công");
        } catch (error) {
            handleCallApiError(error);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        getRoles();
    }, [reload]);

    if (loading)
        return (
            <Box margin={2}>
                <CircularProgress />
            </Box>
        );

    return (
        <>
            <StyledRoleList>
                <Typography variant="h6" marginBottom={1}>
                    Vai trò
                </Typography>
                <Box className="warper">
                    <Box className="option-field-content">
                        <List sx={{ width: "100%", padding: 0 }}>
                            {roles.map((role) => (
                                <ListItem
                                    key={role.name}
                                    secondaryAction={
                                        <>
                                            <Tooltip title="Chỉnh sửa">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleOpenDialog("update", role)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Xóa">
                                                <IconButton onClick={() => handleDeleteRole(role.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton
                                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                                        onClick={() => setSelectedRole({ ...role })}
                                        selected={selectedRole?.id === role.id}
                                    >
                                        <ListItemText id={role.name} primary={role.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Button fullWidth variant="contained" onClick={() => handleOpenDialog("create")}>
                        Thêm vai trò
                    </Button>
                </Box>
            </StyledRoleList>
            <Dialog
                title={dialogType === "create" ? "Thêm vai trò" : "Cập nhật vai trò"}
                maxWidth="sm"
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogContent>
                    {role && (
                        <Box>
                            <Box mb={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Tên vai trò:
                                </Typography>
                                <TextField
                                    error={errorRole.name}
                                    value={role.name}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handChangeRole(event, "name")}
                                    placeholder="Tên vai trò"
                                />
                            </Box>
                            <Box mb={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Mã vai trò:
                                </Typography>
                                <TextField
                                    error={errorRole.key}
                                    value={role.key}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handChangeRole(event, "key")}
                                    placeholder="Mã vai trò"
                                />
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                        Hủy
                    </Button>
                    {dialogType === "create" ? (
                        <LoadingButton variant="contained" color="success" loading={waiting} onClick={handleCreateRole}>
                            Thêm mới
                        </LoadingButton>
                    ) : (
                        <LoadingButton variant="contained" color="success" loading={waiting} onClick={handleUpdateRole}>
                            Lưu thay đổi
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RoleList;
