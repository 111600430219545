import api from "./api";

const createCheckList = async ({ customerId, type, note, vaccines = [], scheduleId, storeId }) => {
    const response = await api({
        method: "POST",
        url: "/check-lists",
        data: { customerId, type, note, vaccines, scheduleId, storeId },
    });

    return response;
};

const getCheckLists = async ({ search, status, limit, offset, storeId }) => {
    const response = await api({
        method: "GET",
        url: "/check-lists",
        params: { search, status, limit, offset, storeId },
    });

    return response;
};

const deleteCheckList = async (checkListId) => {
    const response = await api({
        method: "DELETE",
        url: `/check-lists/${checkListId}`,
    });
    return response;
};

const saveExaminationSheet = async ({ checkListId, examinationResult, vaccines, note }) => {
    const response = await api({
        method: "PUT",
        url: `/check-lists/${checkListId}`,
        data: { examinationResult, vaccines, note },
    });

    return response;
};

const getCustomerAppointmentsHistory = async ({ customerId, search, status, start, end, limit, offset, storeId }) => {
    const response = await api({
        method: "GET",
        url: `/appointment-history`,
        params: { customerId, search, status, start, end, limit, offset, storeId },
    });

    return response;
};

const updateCheckList = async (checkListId, updateData) => {
    const response = await api({
        method: "PUT",
        url: `/check-lists/status/${checkListId}`,
        data: updateData,
    });

    return response;
};

export {
    createCheckList,
    getCheckLists,
    deleteCheckList,
    saveExaminationSheet,
    getCustomerAppointmentsHistory,
    updateCheckList,
};
