import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dialog from "../../components/Dialog";
import { USER_STATUS, INIT_USER, INIT_USER_ERROR } from "../../constants/user";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { validateEmail } from "../../utils/mail";

const UserDialog = ({ open, handleClose, user, setUser, handleReload, dialogType }) => {
    const [userError, setUserError] = useState(INIT_USER_ERROR);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const [roles, setRoles] = useState([]);

    const store = useSelector((state) => state.store);

    const fetchRoles = async () => {
        try {
            const res = await apis.role.getRoles();
            setRoles(res.roles);
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const handleChangeUser = (event, field) => {
        const value = event.target.value;
        setUser((prev) => ({ ...prev, [field]: value }));
        setUserError((prev) => ({ ...prev, [field]: false }));
    };

    const handleChangeDate = (newValue) => {
        setUser((prev) => ({ ...prev, dateOfBirth: newValue }));
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.checked);
    };

    const handleCloseDialog = () => {
        handleClose();
        setUserError(INIT_USER_ERROR);
        setUser({});
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...userError };
        Object.keys(INIT_USER).forEach((key) => {
            if (
                user[key] === INIT_USER[key] ||
                (key === "phoneNumber" && !/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(user[key])) ||
                (key === "email" && !validateEmail(user[key]))
            ) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setUserError(error);
        return isSuccess;
    };

    const handleCreateUser = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            await apis.user.createUser({ ...user, dateOfBirth: dayjs(user.dateOfBirth).format("DD-MM-YYYY") });
            handleCloseDialog();
            toast.success("Thêm nhân sự mới thành công");
            handleReload();
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleUpdateUser = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            const newUser = { ...user };
            delete newUser.email;
            const res = await apis.user.updateUser(user.id, {
                ...newUser,
                status: status ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE,
                dateOfBirth: dayjs(newUser.dateOfBirth).format("DD-MM-YYYY"),
            });
            if (res.status === 1) {
                handleCloseDialog();
                toast.success("Cập nhật thông tin thành công");
                handleReload();
            } else {
                toast.error("Cập nhật thông tin thất bại");
            }
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setStatus(user?.status === USER_STATUS.ACTIVE);
    }, [user]);

    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <Dialog
            title={dialogType === "create" ? "Thêm nhân sự" : "Cập nhật thông tin nhân sự"}
            maxWidth="md"
            open={open}
            onClose={handleCloseDialog}
        >
            <DialogContent>
                {user && Object.keys(user).length > 0 && (
                    <Box>
                        <Grid container spacing={3} mb={2}>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Họ và tên:
                                </Typography>
                                <TextField
                                    error={userError.name}
                                    value={user.name}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeUser(event, "name")}
                                    placeholder="Họ và tên"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Địa chỉ email:
                                </Typography>
                                <TextField
                                    error={userError.email}
                                    value={user.email}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeUser(event, "email")}
                                    placeholder="Địa chỉ email"
                                    disabled={dialogType === "update"}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Số điện thoại:
                                </Typography>
                                <TextField
                                    error={userError.phoneNumber}
                                    value={user.phoneNumber}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeUser(event, "phoneNumber")}
                                    placeholder="Số điện thoại"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Ngày sinh:
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        name="startDate"
                                        inputFormat="DD/MM/YYYY"
                                        value={user.dateOfBirth}
                                        onChange={(newValue) => handleChangeDate(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                {...params}
                                                error={userError.dateOfBirth}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Cửa hàng:
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={user.storeId}
                                        onChange={(event) => handleChangeUser(event, "storeId")}
                                        size="small"
                                        error={userError.dateOfBirth}
                                    >
                                        {store.stores.map((st) => (
                                            <MenuItem key={st.id} value={st.id}>
                                                {st.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Vai trò:
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={user.roleId}
                                        onChange={(event) => handleChangeUser(event, "roleId")}
                                        size="small"
                                        error={userError.roleId}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {dialogType === "update" && (
                            <Stack mb={3} direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Typography fontWeight={500}>Trạng thái:</Typography>
                                <FormControlLabel
                                    control={<Switch checked={status} onChange={handleChangeStatus} />}
                                    label={status ? "Hoạt động" : "Không hoạt động"}
                                />
                            </Stack>
                        )}
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                    Hủy
                </Button>
                {dialogType === "create" ? (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleCreateUser}>
                        Thêm mới
                    </LoadingButton>
                ) : (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleUpdateUser}>
                        Lưu thay đổi
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UserDialog;
