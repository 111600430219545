import styled from "styled-components";

export const InputWrapper = styled.div`
    margin-bottom: 15px;

    label {
        display: block;
        margin-bottom: 8px;
    }

    input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    input:focus {
        outline: none;
    }

    .error {
        color: red;
        font-size: 12px;
    }
`;
