import { Box, styled } from "@mui/material";

import { TRANSPARENT_COLOR } from "../../styles/color";

export const StyledAssignInjection = styled(Box)`
    .injection-service {
        padding: 10px 15px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        background-color: ${TRANSPARENT_COLOR.primary};
    }
`;
