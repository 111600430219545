import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, Divider, Grid, Stack, TextField, Typography } from "@mui/material";

import Dialog from "../../../components/Dialog";
import { VACCINE_STATUS, INT_VACCINE_ERROR } from "../../../constants/vaccines";
import { handleCallApiError } from "../../../errors";
import apis from "../../../apis";
import { setVaccines } from "../../../stores/vaccineSlice";
import { ALL_STORE } from "../../../constants";

const VaccineDialog = ({ open, handleClose, vaccine, setVaccine, handleReload }) => {
    const [vaccineError, setVaccineError] = useState(INT_VACCINE_ERROR);
    const [loading, setLoading] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);

    const dispatch = useDispatch();

    const getStore = async () => {
        if (currentStore !== ALL_STORE) {
            try {
                const { store } = await apis.store.getStore(currentStore);
                const vaccines = store.vaccines
                    .filter((vaccine) => vaccine.status === VACCINE_STATUS.ACTIVE)
                    .map(({ detail, ...vaccineInfo }) => {
                        delete detail.storeId;
                        delete detail.vaccineId;
                        return { ...detail, ...vaccineInfo };
                    });
                dispatch(setVaccines(vaccines));
            } catch (error) {
                handleCallApiError(error);
            }
        }
    };

    const handleChangeVaccine = (event, field) => {
        const value = event.target.value;
        setVaccine((prev) => ({ ...prev, [field]: value }));
        const intValue = parseInt(value, 10);
        if (intValue < 0) setVaccineError((prev) => ({ ...prev, [field]: true }));
        else setVaccineError((prev) => ({ ...prev, [field]: false }));
    };

    const handleCloseDialog = () => {
        handleClose();
        setVaccineError(INT_VACCINE_ERROR);
        setVaccine({});
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...vaccineError };
        ["price", "cost", "amount", "discount"].forEach((key) => {
            const intValue = parseInt(key, 10);
            if (intValue < 0) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setVaccineError(error);
        return isSuccess;
    };

    const handleUpdateVaccine = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            const { price, cost, amount, discount, storeId, id: vaccineId } = vaccine;
            const res = await apis.warehouse.updateVaccine({
                price,
                cost,
                amount,
                discount,
                storeId,
                vaccineId,
            });
            if (res.status === 1) {
                handleCloseDialog();
                toast.success("Cập nhật thông tin thành công");
                handleReload();
                getStore();
            } else {
                toast.error("Cập nhật thông tin thất bại");
            }
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    return (
        <Dialog title={"Cập nhật số liệu vắc xin"} maxWidth="md" open={open} onClose={handleCloseDialog}>
            <DialogContent>
                {vaccine && Object.keys(vaccine).length > 0 && (
                    <Box>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={4}>
                                <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} mb={1}>
                                    <Typography fontWeight={500}>Tên vắc xin:</Typography>
                                    <Typography>{vaccine.name}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} mb={1}>
                                    <Typography fontWeight={500}>Mô tả:</Typography>
                                    <Typography>{vaccine.desc}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3}>
                                <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} mb={1}>
                                    <Typography fontWeight={500}>Xuất xứ:</Typography>
                                    <Typography>{vaccine.origin}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Giá niêm yết:
                                </Typography>
                                <TextField
                                    error={vaccineError.price}
                                    value={vaccine.price}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "price")}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Giá nhập vào:
                                </Typography>
                                <TextField
                                    error={vaccineError.cost}
                                    value={vaccine.cost}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "cost")}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Số lượng:
                                </Typography>
                                <TextField
                                    error={vaccineError.amount}
                                    value={vaccine.amount}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "amount")}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Khuyến mãi:
                                </Typography>
                                <TextField
                                    error={vaccineError.discount}
                                    value={vaccine.discount}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "discount")}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                    Hủy
                </Button>

                <LoadingButton variant="contained" color="success" loading={loading} onClick={handleUpdateVaccine}>
                    Lưu thay đổi
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default VaccineDialog;
