import React, { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";

import { StyledVaccine } from "./index.style";
import VaccineList from "./VaccineList";
import { INT_VACCINE } from "../../constants/vaccines";
import VaccineDialog from "./VaccineDialog";

const INITIAL_PAGING = { page: 1, total: 0 };

const Vaccine = () => {
    const [search, setSearch] = useState("");
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [vaccineDialog, setVaccineDialog] = useState(false);
    const [vaccine, setVaccine] = useState({});
    const [reload, setReload] = useState(false);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleOpenVaccineDialog = () => {
        setVaccine(INT_VACCINE);
        setVaccineDialog(true);
    };

    const handleCloseVaccineDialog = () => {
        setVaccineDialog(false);
    };

    const handleReload = () => {
        setReload((prev) => !prev);
    };

    return (
        <>
            <StyledVaccine>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm vắc xin theo tên, mô tả hoặc xuất xứ"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            color="success"
                            sx={{ paddingTop: "7px", paddingBottom: "6.5px", marginRight: "5px" }}
                            onClick={handleOpenVaccineDialog}
                        >
                            Thêm vắc xin
                        </Button>
                    </Stack>
                </div>
                <VaccineList search={search} paging={paging} onChangePage={handleChangePaging} reload={reload} />
            </StyledVaccine>
            <VaccineDialog
                open={vaccineDialog}
                handleClose={handleCloseVaccineDialog}
                vaccine={vaccine}
                setVaccine={setVaccine}
                handleReload={handleReload}
                dialogType="create"
            />
        </>
    );
};

export default Vaccine;
