import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import LOGO from "../../../assets/images/logo-nutivac.jpg";
import dayjs from "dayjs";
import { renderTotalPrice } from "../../../utils/checklist";
import { formatMoney } from "../../../utils/money";
import { READABLE_PAYMENT_TYPE } from "../../../constants/payment";

const ReceiptSheet = React.forwardRef(({ checkList, paymentType }, ref) => {
    return (
        <>
            <div ref={ref} style={{ width: "100%", heigh: window.innerHeight }}>
                <Box sx={{ paddingX: 10, paddingY: 6 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <img src={LOGO} alt="no data" width="60" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography
                                    align="center"
                                    fontWeight={700}
                                    fontSize={18}
                                    sx={{ textTransform: "uppercase" }}
                                >
                                    PHÒNG TIÊM CHỦNG VẮC XIN {checkList?.store?.name}
                                </Typography>
                                <Typography align="center">Địa chỉ: {checkList?.store?.address}</Typography>
                                <Typography align="center">Hotline: {checkList?.store?.hotline}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography align="center" fontWeight={700} fontSize={20} mb={3} textTransform="uppercase">
                        PHIẾU THU
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} mb={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                Họ và tên:
                            </Grid>
                            <Grid item xs={9}>
                                <Typography fontWeight={500} textTransform="uppercase">
                                    {checkList?.customer?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Ngày sinh:
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>{checkList?.customer?.dateOfBirth}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Số điện thoại:
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>{checkList?.customer?.phoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Địa chỉ:
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{checkList?.customer?.address}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                Hình thức thanh toán:
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>{READABLE_PAYMENT_TYPE[paymentType]}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} mb={3}>
                        <table style={{ width: "100%", borderCollapse: "collapse", border: "none" }}>
                            <thead>
                                <tr>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>STT</th>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>Vắc xin</th>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>Phòng bệnh</th>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>Liều lượng</th>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>Xuất xứ</th>
                                    <th style={{ border: "1px solid #333333", padding: "5px" }}>Giá</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkList?.vaccines?.map((vaccine, index) => (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #333333", padding: "5px" }}
                                        >
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #333333", padding: "5px" }}
                                        >
                                            {vaccine.name}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #333333", padding: "5px" }}
                                        >
                                            {vaccine.desc}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #333333", padding: "5px" }}
                                        >
                                            {vaccine.detail.dosage}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #333333", padding: "5px" }}
                                        >
                                            {vaccine.origin}
                                        </td>
                                        <td style={{ textAlign: "end", border: "1px solid #333333", padding: "5px" }}>
                                            <Typography>{formatMoney(vaccine.detail.price)}</Typography>
                                            {vaccine.detail.discount !== 0 && (
                                                <Typography fontSize="12.5px">
                                                    - {formatMoney(vaccine.detail.discount)}
                                                </Typography>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td
                                        colSpan={5}
                                        style={{
                                            textAlign: "center",
                                            border: "1px solid #333333",
                                            padding: "5px",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Tổng
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "end",
                                            border: "1px solid #333333",
                                            padding: "5px",
                                            fontWeight: 500,
                                        }}
                                    >
                                        {renderTotalPrice(checkList?.vaccines || []).replace(" VNĐ", "")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} mb={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography textAlign="center" fontStyle="italic" fontWeight={500}>
                                    Ngày {dayjs().format("DD/MM/YYYY HH:mm")}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography textAlign="center" fontWeight={500}>
                                    Thu ngân
                                </Typography>
                                <Typography textAlign="center" fontStyle="italic">
                                    (Ký, ghi rõ họ và tên)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    );
});

export default ReceiptSheet;
