import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

import { StyledGroupPermission } from "./index.style";
import Dialog from "../../components/Dialog";
import { LoadingButton } from "@mui/lab";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";

const INT_GROUP_PERMISSION_ERROR = {
    name: false,
};

const INT_GROUP_PERMISSION = {
    name: "",
};

const GroupPermissionList = ({ selectedGroupPermissionId, setSelectedGroupPermissionId }) => {
    const [errorGroupPermission, setErrorGroupPermission] = useState(INT_GROUP_PERMISSION_ERROR);
    const [openDialog, setOpenDialog] = useState(false);
    const [groupPermission, setGroupPermission] = useState(INT_GROUP_PERMISSION);
    const [dialogType, setDialogType] = useState("create");
    const [loading, setLoading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [groupPermissions, setGroupPermissions] = useState([]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setErrorGroupPermission(INT_GROUP_PERMISSION_ERROR);
        setGroupPermission(INT_GROUP_PERMISSION);
    };

    const handleOpenDialog = (type, object) => {
        setDialogType(type);
        if (object) setGroupPermission(object);
        setOpenDialog(true);
    };

    const handChangeGroupPermission = (event, field) => {
        const value = event.target.value;
        setGroupPermission((prev) => ({ ...prev, [field]: value }));
        setErrorGroupPermission((prev) => ({ ...prev, [field]: false }));
    };

    const getGroupPermissions = async () => {
        try {
            const res = await apis.groupPermission.getGroupPermissions();
            setGroupPermissions(res.groupPermissions);
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const fetchGroupPermissions = async () => {
        setLoading(true);
        await getGroupPermissions();
        setLoading(false);
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...errorGroupPermission };
        Object.keys(INT_GROUP_PERMISSION_ERROR).forEach((key) => {
            if (groupPermission[key] === INT_GROUP_PERMISSION[key]) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setErrorGroupPermission(error);
        return isSuccess;
    };

    const handleCreateGroupPermission = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.groupPermission.createGroupPermission(groupPermission);
            handleCloseDialog();
            getGroupPermissions();
            toast.success("Thêm nhóm quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleUpdateGroupPermission = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.groupPermission.updateGroupPermission(groupPermission.id, groupPermission);
            handleCloseDialog();
            getGroupPermissions();
            toast.success("Cập nhật nhóm quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleDeleteGroupPermission = async (groupPermissionId) => {
        try {
            await apis.groupPermission.deleteGroupPermission(groupPermissionId);
            if (selectedGroupPermissionId === groupPermissionId) setSelectedGroupPermissionId(null);
            handleCloseDialog();
            getGroupPermissions();
            toast.success("Xóa nhóm quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
    };

    useEffect(() => {
        fetchGroupPermissions();
    }, []);

    if (loading)
        return (
            <Box margin={2}>
                <CircularProgress />
            </Box>
        );

    return (
        <>
            <StyledGroupPermission>
                <Typography variant="h6" marginBottom={1}>
                    Nhóm quyền
                </Typography>
                <Box className="warper">
                    <Box className="option-field-content">
                        <List sx={{ width: "100%", padding: 0 }}>
                            {groupPermissions.map((groupPermission) => (
                                <ListItem
                                    key={groupPermission.name}
                                    secondaryAction={
                                        <>
                                            <Tooltip title="Chỉnh sửa">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleOpenDialog("update", groupPermission)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Xóa">
                                                <IconButton
                                                    onClick={() => handleDeleteGroupPermission(groupPermission.id)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton
                                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                                        onClick={() => setSelectedGroupPermissionId(groupPermission.id)}
                                        selected={selectedGroupPermissionId === groupPermission.id}
                                    >
                                        <ListItemText id={groupPermission.name} primary={groupPermission.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Button fullWidth variant="contained" onClick={() => handleOpenDialog("create")}>
                        Thêm nhóm quyền
                    </Button>
                </Box>
            </StyledGroupPermission>
            <Dialog
                title={dialogType === "create" ? "Thêm nhóm quyền" : "Cập nhật nhóm quyền"}
                maxWidth="sm"
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogContent>
                    {groupPermission && (
                        <Box mb={3}>
                            <Typography fontWeight={500} mb={1}>
                                Tên nhóm quyền:
                            </Typography>
                            <TextField
                                error={errorGroupPermission.name}
                                value={groupPermission.name}
                                size="small"
                                fullWidth
                                onChange={(event) => handChangeGroupPermission(event, "name")}
                                placeholder="Tên nhóm quyền"
                            />
                        </Box>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                        Hủy
                    </Button>
                    {dialogType === "create" ? (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            loading={waiting}
                            onClick={handleCreateGroupPermission}
                        >
                            Thêm mới
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            loading={waiting}
                            onClick={handleUpdateGroupPermission}
                        >
                            Lưu thay đổi
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GroupPermissionList;
