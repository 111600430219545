import { InputWrapper } from "./styles";

const CustomInput = (props) => {
    const { field, form, type, label, placeholder, disabled } = props;
    const { name } = field; // name, values, onChange, onBlur
    const { errors, touched } = form;
    const showError = errors[name] && touched[name];

    return (
        <InputWrapper>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                id={name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
            />
            {showError && <span className="error">{errors[name]}</span>}
        </InputWrapper>
    );
};

CustomInput.defaultProps = {
    type: "text",
    label: "",
    placeholder: "",
    disabled: false,
};

export default CustomInput;
