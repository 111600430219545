import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            return { ...action.payload };
        },
    },
});

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
