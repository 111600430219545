import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import tabs from "./config";

const Doctor = () => {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {tabs.map((tab) => (
                            <Tab label={tab.name} value={tab.value} key={tab.value} />
                        ))}
                    </TabList>
                </Box>
                {tabs.map((tab) => (
                    <TabPanel key={tab.value} value={tab.value}>
                        {tab.component}
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
};

export default Doctor;
