import api from "./api";

const getVaccines = async ({ search, method, origin, limit, offset }) => {
    const response = await api({
        method: "GET",
        url: "/vaccines",
        params: { search, method, origin, limit, offset },
    });

    return response;
};

const createVaccine = async (data) => {
    const response = await api({
        method: "POST",
        url: "/vaccines",
        data,
    });

    return response;
};

const updateVaccine = async (vaccineId, data) => {
    const response = await api({
        method: "PUT",
        url: `/vaccines/${vaccineId}`,
        data,
    });

    return response;
};

export { getVaccines, createVaccine, updateVaccine };
