import api from "./api";

const getStores = async ({ search, limit, offset, status }) => {
    const response = await api({
        method: "GET",
        url: "/stores",
        params: { search, limit, offset, status },
    });

    return response;
};

const getStore = async (storeId) => {
    const response = await api({
        method: "GET",
        url: `/stores/${storeId}`,
    });

    return response;
};

const createStore = async (data) => {
    const response = await api({
        method: "POST",
        url: "/stores",
        data,
    });

    return response;
};

const updateStore = async (storeId, data) => {
    const response = await api({
        method: "PUT",
        url: `/stores/${storeId}`,
        data,
    });

    return response;
};

export { getStores, getStore, createStore, updateStore };
