import React, { useEffect, useState } from "react";
import { Male, Female, AddCircleOutline, Edit } from "@mui/icons-material";
import { IconButton, Box, Tooltip, Typography } from "@mui/material";
import { pink } from "@mui/material/colors";

import Table from "../../../components/Table";
import apis from "../../../apis";
import debounce from "../../../utils/debounce";
import { PAGINATION_LIMIT } from "../../../constants";
import { COLOR } from "../../../styles/color";
import { handleCallApiError } from "../../../errors";
import CreateCheckListDialog from "../../CreateCheckListDialog";
import CreateCustomerDialog from "../../CreateCustomerDialog";
import CustomerDetail from "./CustomerDetail";

const CustomerList = ({ search, paging, onChangePage, reload }) => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [registrationDialog, setRegistrationDialog] = useState(false);
    const [customer, setCustomer] = useState({});
    const [customerDialog, setCustomerDialog] = useState(false);
    const [customerDetail, setCustomerDetail] = useState(false);

    const handleChangeRegistrationDialog = (row = null) => {
        setRegistrationDialog((prev) => !prev);
        row ? setCustomer(row) : setCustomer({});
    };

    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const data = await apis.customer.getCustomers({
                search,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            });
            setCustomers(data?.customers || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleCloseCustomerDialog = () => {
        setCustomer({});
        setCustomerDialog(false);
    };

    const handleOpenCustomerDialog = (row) => {
        setCustomer(row);
        setCustomerDialog(true);
    };

    const handleOpenCustomerDetail = (row) => {
        setCustomer(row);
        setCustomerDetail(true);
    };

    const handleCloseCustomerDetail = () => {
        setCustomer({});
        setCustomerDetail(false);
    };

    const columns = [
        {
            field: "id",
            title: "Mã tiêm chủng",
            align: "left",
        },
        {
            field: "name",
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <Typography
                    sx={{ cursor: "pointer" }}
                    color="primary"
                    fontSize="13px"
                    onClick={() => handleOpenCustomerDetail(row)}
                >
                    {row.name}
                </Typography>
            ),
        },
        {
            field: "gender",
            title: "Giới tính",
            align: "center",
            render: (row) =>
                !row.gender ? (
                    <Male fontSize="large" color="primary" />
                ) : (
                    <Female fontSize="large" sx={{ color: pink[300] }} />
                ),
        },
        {
            field: "dateOfBirth",
            title: "Ngày sinh",
            align: "left",
        },
        {
            field: "address",
            title: "Địa chỉ thường trú",
            align: "left",
        },
        {
            field: "parentName",
            title: "Người bảo hộ",
            align: "left",
        },
        {
            field: "phoneNumber",
            title: "Số điện thoại",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Box display="flex" justifyContent="center">
                    <Tooltip title="Đăng ký khám">
                        <IconButton onClick={() => handleChangeRegistrationDialog(row)}>
                            <AddCircleOutline sx={{ color: COLOR.primary }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Chỉnh sửa">
                        <IconButton onClick={() => handleOpenCustomerDialog(row)}>
                            <Edit sx={{ color: COLOR.purple }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchCustomers, 500)(search);
    }, [paging.page, search, reload]);

    return (
        <>
            <Table
                data={customers}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <CreateCheckListDialog
                handleCloseRegistrationDialog={handleChangeRegistrationDialog}
                registrationDialog={registrationDialog}
                customer={customer}
            />
            <CreateCustomerDialog
                handleCloseCustomerDialog={handleCloseCustomerDialog}
                customerDialog={customerDialog}
                customer={customer}
                setCustomer={setCustomer}
                handleReload={fetchCustomers}
                dialogType="update"
            />
            <CustomerDetail open={customerDetail} handleClose={handleCloseCustomerDetail} customer={customer} />
        </>
    );
};

export default CustomerList;
