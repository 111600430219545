import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    FormControl,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import { StyledAssignInjection } from "./index.style";
import { formatMoney } from "../../utils/money";
import { INIT_ASSIGN_VACCINE, INIT_ASSIGN_VACCINE_ERROR } from "../../constants/vaccines";
import { NUMBER_OF_INJECTION } from "../../constants/vaccines";

const AssignInjection = ({
    assignVaccines,
    setAssignVaccines,
    assignVaccinesError,
    setAssignVaccinesError,
    canEdit,
}) => {
    const [dosages, setDosages] = useState([]);
    const vaccines = useSelector((state) => state.vaccine);

    const getDosage = (dosage) => {
        if (!dosage) return [];
        dosage = dosage.split(",").filter((item) => item.trim());
        return dosage;
    };

    const handleChangeVaccine = (newValue, index) => {
        let assignVaccinesTmp = [...assignVaccines];
        const vaccineId = newValue ? parseInt(newValue, 10) : null;
        assignVaccinesTmp[index] = { ...assignVaccinesTmp[index], vaccineId };
        const vaccine = vaccines.find((vc) => vc.id === vaccineId);
        if (vaccine) {
            assignVaccinesTmp[index] = { ...assignVaccinesTmp[index], discount: vaccine.discount };
            const dosage = getDosage(vaccine.dosage);
            if (dosage.length > 0) assignVaccinesTmp[index] = { ...assignVaccinesTmp[index], dosage: dosage[0] };
        }
        setAssignVaccines(assignVaccinesTmp);
        handleChangeError(index, { vaccineId: false });
    };

    const handleChangeNumberOfInjections = (event, index) => {
        let vaccines = [...assignVaccines];
        vaccines[index] = { ...vaccines[index], numberOfInjections: event.target.value };
        setAssignVaccines(vaccines);
    };

    const handleChangeDosage = (event, index) => {
        let vaccines = [...assignVaccines];
        vaccines[index] = { ...vaccines[index], dosage: event.target.value };
        setAssignVaccines(vaccines);
        handleChangeError(index, { dosage: false });
    };

    const handleChangeDiscount = (event, index) => {
        let vaccines = [...assignVaccines];
        vaccines[index] = { ...vaccines[index], discount: parseInt(event.target.value, 10) };
        setAssignVaccines(vaccines);
        handleChangeError(index, { dosage: false });
    };

    const handleChangeError = (index, obj) => {
        let vaccinesError = [...assignVaccinesError];
        vaccinesError[index] = { ...vaccinesError[index], ...obj };
        setAssignVaccinesError(vaccinesError);
    };

    const handleDeleteVaccine = (index) => {
        let vaccines = [...assignVaccines];
        vaccines.splice(index, 1);
        setAssignVaccines([...vaccines]);
        let vaccinesError = [...assignVaccinesError];
        vaccinesError.splice(index, 1);
        setAssignVaccinesError(vaccinesError);
        let dosagesTmp = [...dosages];
        dosagesTmp.splice(index, 1);
        setDosages(dosagesTmp);
    };

    const handleAddVaccine = () => {
        setAssignVaccines((prev) => [...prev, INIT_ASSIGN_VACCINE]);
        setAssignVaccinesError((prev) => [...prev, INIT_ASSIGN_VACCINE_ERROR]);
        setDosages((prev) => [...prev, []]);
    };

    const initDosages = (assignVaccines) => {
        const vaccineIds = assignVaccines.map((assignVaccine) => assignVaccine.vaccineId);
        const dosagesTmp = vaccineIds.map((id) => {
            const vaccine = vaccines.find((vc) => vc.id === id);
            if (!vaccine) return [];
            return getDosage(vaccine.dosage);
        });
        setDosages(dosagesTmp);
    };

    const renderTotalPrice = () => {
        return `${formatMoney(
            assignVaccines.reduce((total, assignVaccine) => {
                if (!assignVaccine.vaccineId) return total;
                const vaccine = vaccines.find((vaccine) => vaccine.id === assignVaccine.vaccineId);
                return total + (vaccine.price - assignVaccine.discount);
            }, 0)
        )} VNĐ`;
    };

    const renderVaccineName = (key) => {
        const vaccine = vaccines.find((vc) => vc.id === key);
        return `${vaccine.name} | ${vaccine.desc}`;
    };

    const renderDosage = (dosage) => {
        if (!dosage?.length) return <MenuItem value="">No options</MenuItem>;
        return dosage.map((item) => (
            <MenuItem key={item} value={item}>
                {item}
            </MenuItem>
        ));
    };

    useEffect(() => {
        initDosages(assignVaccines);
    }, [assignVaccines]);

    return (
        <StyledAssignInjection>
            <Box mb={1}>
                <Typography className="injection-service">Chỉ định vắc xin</Typography>
                <Box mb={2}>
                    {assignVaccines.map((vaccine, index) => (
                        <Grid
                            key={index}
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            marginBottom={1}
                        >
                            <Grid item xs={2} sm={4} md={5}>
                                <Typography fontWeight={500} mb={1}>
                                    Vắc xin:
                                </Typography>
                                <Autocomplete
                                    disabled={!canEdit}
                                    size="small"
                                    value={vaccine.vaccineId}
                                    options={vaccines.map((vaccine) => vaccine.id)}
                                    getOptionLabel={(option) => renderVaccineName(option)}
                                    filterSelectedOptions
                                    onChange={(event, newValue) => handleChangeVaccine(newValue, index)}
                                    renderOption={(props, key) => (
                                        <MenuItem value={key} {...props}>
                                            {renderVaccineName(key)}
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} error={assignVaccinesError[index]?.vaccineId} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={2}>
                                <Typography fontWeight={500} mb={1}>
                                    Mũi:
                                </Typography>
                                <FormControl fullWidth disabled={!canEdit}>
                                    <Select
                                        value={vaccine.numberOfInjections}
                                        size="small"
                                        onChange={(event) => handleChangeNumberOfInjections(event, index)}
                                    >
                                        {NUMBER_OF_INJECTION.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={4} md={2}>
                                <Typography fontWeight={500} mb={1}>
                                    Liều lượng:
                                </Typography>
                                <FormControl fullWidth error={assignVaccinesError[index]?.dosage} disabled={!canEdit}>
                                    <Select
                                        value={vaccine.dosage}
                                        size="small"
                                        onChange={(event) => handleChangeDosage(event, index)}
                                    >
                                        {renderDosage(dosages[index])}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={4} md={2}>
                                <Typography fontWeight={500} mb={1}>
                                    Khuyến mãi:
                                </Typography>

                                <TextField
                                    error={assignVaccinesError.discount}
                                    value={vaccine.discount}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeDiscount(event, index)}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={1} sx={{ textAlign: "end" }} alignSelf="flex-end">
                                {assignVaccines.length > 1 && (
                                    <Tooltip title="Xóa vắc xin">
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() => handleDeleteVaccine(index)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                {canEdit && (
                    <Button variant="outlined" startIcon={<Add />} onClick={handleAddVaccine}>
                        Thêm
                    </Button>
                )}
            </Box>
            <Box mb={1}>
                <Typography align="right" fontWeight={500} fontSize="18px">
                    Tổng: {renderTotalPrice()}
                </Typography>
            </Box>
        </StyledAssignInjection>
    );
};

export default AssignInjection;
