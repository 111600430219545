import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import {
    APPOINTMENT_HISTORY_ALL_STATUS,
    APPOINTMENT_HISTORY_STATUS,
    APPOINTMENT_HISTORY_STATUS_DEFAULT,
} from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import apis from "../../../apis";
import { handleCallApiError } from "../../../errors";
import Table from "../../../components/Table";
import { PAGINATION_LIMIT } from "../../../constants";

const AppointmentsHistory = ({ status, paging, onChangePage, reload, customerId }) => {
    const [appointmentsHistory, setAppointmentsHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const checkList = useSelector((state) => state.checkList);

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const fetchAppointmentsHistory = async () => {
        setLoading(true);
        try {
            const data = await apis.checkList.getCustomerAppointmentsHistory({
                customerId: checkList?.customer?.id || customerId,
                status: status === APPOINTMENT_HISTORY_ALL_STATUS ? null : status,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            });
            setAppointmentsHistory(data?.appointmentsHistory);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAppointmentsHistory();
    }, [paging.page, status, reload]);

    const columns = [
        {
            title: "Trạng thái",
            align: "center",
            render: (row) => (
                <Typography color={APPOINTMENT_HISTORY_STATUS[row.status].color}>
                    {APPOINTMENT_HISTORY_STATUS[row.status].label}
                </Typography>
            ),
        },
        {
            title: "Ngày hẹn",
            align: "center",
            render: (row) => dayjs(row.schedule).format("DD/MM/YYYY"),
        },
        {
            title: "Vắc xin",
            align: "left",
            render: (row) => `${row.vaccines[0].name} | ${row.vaccines[0].desc}`,
        },
        {
            title: "Mũi",
            align: "center",
            render: (row) => row.vaccines[0].detail.numberOfInjections,
        },
        {
            title: "Ghi chú",
            align: "left",
            render: (row) => row.vaccines[0].detail.note,
        },
        {
            title: "Ngày tạo",
            align: "left",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY"),
        },
        {
            title: "...",
            align: "center",
            render: (row) =>
                row.status === APPOINTMENT_HISTORY_STATUS_DEFAULT &&
                dayjs().format("DD/MM/YYYY") === dayjs(row.createdAt).format("DD/MM/YYYY") && (
                    <Box display="flex" justifyContent="center">
                        <Tooltip title="Xóa lịch hẹn">
                            <IconButton>
                                <Delete sx={{ color: COLOR.grey }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
        },
    ];

    return (
        <>
            <Table
                data={appointmentsHistory}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
        </>
    );
};

export default AppointmentsHistory;
