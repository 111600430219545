import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";

import { StyledVaccine } from "./index.style";
import VaccineList from "./VaccineList";

const INITIAL_PAGING = { page: 1, total: 0 };

const Vaccine = () => {
    const [search, setSearch] = useState("");
    const [paging, setPaging] = useState(INITIAL_PAGING);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    return (
        <StyledVaccine>
            <div className="filter">
                <div className="search">
                    <TextField
                        value={search}
                        size="small"
                        fullWidth
                        placeholder={"Tìm kiếm vắc xin theo tên, mô tả hoặc xuất xứ"}
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Box></Box>
            </div>
            <VaccineList search={search} paging={paging} onChangePage={handleChangePaging} />
        </StyledVaccine>
    );
};

export default Vaccine;
