import api from "./api";

const login = async (email, password) => {
    const loginInfo = await api({
        method: "POST",
        url: "/login",
        data: { email, password },
    });
    return loginInfo;
};

const getMe = async () => {
    const me = await api({
        method: "GET",
        url: "/me",
    });
    return me;
};

const logout = async () => {
    const logoutInfo = await api({
        method: "GET",
        url: "/logout",
    });
    return logoutInfo;
};

export { login, getMe, logout };
