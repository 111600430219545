import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { store } from "./stores";
import AppRouter from "./routes";

const App = () => {
    return (
        <Provider store={store}>
            <AppRouter />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Provider>
    );
};

export default App;
