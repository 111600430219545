import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { AddCircleOutline } from "@mui/icons-material";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import Table from "../../components/Table";
import apis from "../../apis";
import debounce from "../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../constants";
import { CHECK_LIST_STATUS } from "../../constants/checkList";
import { COLOR } from "../../styles/color";
import { handleCallApiError } from "../../errors";
import { renderStatus } from "../../utils/checklist";
import Dialog from "../../components/Dialog";
import { LoadingButton } from "@mui/lab";

const InjectionList = ({ search, paging, onChangePage, setReload }) => {
    const [checkLists, setCheckLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkList, setCheckList] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [injectionDialog, setInjectionDialog] = useState(false);
    const currentStore = useSelector((state) => state.store.currentStore);

    const handleCloseInjectionDialog = () => {
        setCheckList({});
        setInjectionDialog(false);
    };

    const handleOpenInjectionDialog = (row) => {
        setCheckList(row);
        setInjectionDialog(true);
    };

    const getCheckLists = async () => {
        setReload(true);
        try {
            const condition = {
                search,
                status: CHECK_LIST_STATUS.WAITING_FOR_INJECTION,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCheckLists(condition);
            setCheckLists(data?.checkLists || []);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setReload(false);
    };

    const fetchCheckLists = async () => {
        setLoading(true);
        await getCheckLists();
        setLoading(false);
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleConfirmDialog = async () => {
        setWaiting(true);
        try {
            await apis.checkList.updateCheckList(checkList.id, {
                status: CHECK_LIST_STATUS.WAITING_FOR_REACTION,
            });
            setInjectionDialog(false);
            toast.success("Cập nhật lượt tiêm thành công");
            fetchCheckLists();
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const columns = [
        {
            title: "Trạng thái",
            align: "left",
            render: (row) => renderStatus(row.status),
        },
        {
            field: "order",
            title: "Số phiếu",
            align: "center",
        },
        {
            title: "Thời gian đăng ký",
            align: "center",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Mã tiêm chủng",
            align: "left",
            render: (row) => row.customer.id,
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <Typography color="primary" fontSize="13px">
                    {row.customer.name}
                </Typography>
            ),
        },
        {
            title: "Ngày sinh",
            align: "left",
            render: (row) => row.customer.dateOfBirth,
        },
        {
            title: "Vắc xin chỉ định",
            align: "left",
            render: (row) => (
                <>
                    {row.vaccines.map((vaccine, index) => (
                        <Typography fontSize="13px">{`${index + 1}. ${vaccine.name} | ${vaccine.desc}`}</Typography>
                    ))}
                </>
            ),
        },
        {
            field: "note",
            title: "Ghi chú",
            align: "left",
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Box display="flex" justifyContent="center">
                    <Tooltip title="Xác nhận tiêm">
                        <IconButton onClick={() => handleOpenInjectionDialog(row)}>
                            <AddCircleOutline sx={{ color: COLOR.primary }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchCheckLists, 500)(search);
    }, [paging.page, search, currentStore]);

    useEffect(() => {
        const interval = setInterval(() => {
            getCheckLists();
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, [search]);

    return (
        <>
            <Table
                data={checkLists}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <Dialog title="Xác nhận tiêm" open={injectionDialog} onClose={handleCloseInjectionDialog}>
                <DialogContent sx={{ padding: "10px 15px" }}>
                    <Typography marginBottom={2}>Thông tin tiêm chủng</Typography>
                    <Box marginX={3}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography fontWeight={500} mr={1}>
                                        Trạng thái:
                                    </Typography>
                                    {renderStatus(checkList?.status)}
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} mr={1}>
                                        Họ và tên:
                                    </Typography>
                                    <Typography>{checkList.customer?.name}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={12}>
                                <Box display="flex" alignItems="flex-start">
                                    <Typography fontWeight={500} mr={2}>
                                        Vắc xin chỉ định:
                                    </Typography>
                                    <Box>
                                        {checkList.vaccines?.map((vaccine, index) => (
                                            <Typography key={index} mb={1}>{`${index + 1}. ${vaccine.name} | ${
                                                vaccine.desc
                                            } | ${vaccine.origin}`}</Typography>
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseInjectionDialog}>
                        Hủy
                    </Button>
                    <LoadingButton variant="contained" onClick={handleConfirmDialog} loading={waiting}>
                        Xác nhận
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InjectionList;
