import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import GroupPermissionList from "./GroupPermissionList";
import PermissionList from "./PermissionList";

const Permission = () => {
    const [selectedGroupPermissionId, setSelectedGroupPermissionId] = useState(null);

    return (
        <Box margin={2}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <GroupPermissionList
                        selectedGroupPermissionId={selectedGroupPermissionId}
                        setSelectedGroupPermissionId={setSelectedGroupPermissionId}
                    />
                </Grid>
                <Grid item xs={9}>
                    {selectedGroupPermissionId && (
                        <PermissionList selectedGroupPermissionId={selectedGroupPermissionId} />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Permission;
