import api from "./api";

const getCustomers = async ({ search, limit, offset }) => {
    const response = await api({
        method: "GET",
        url: "/customers",
        params: { search, limit, offset },
    });

    return response;
};

const searchAdvance = async (customerId) => {
    const response = await api({
        method: "GET",
        url: `/search/${customerId}`,
    });

    return response;
};

const createCustomer = async (data) => {
    const response = await api({
        method: "POST",
        url: `/customers`,
        data,
    });

    return response;
};

const updateCustomer = async (customerId, data) => {
    const response = await api({
        method: "PUT",
        url: `/customers/${customerId}`,
        data,
    });

    return response;
};

export { getCustomers, searchAdvance, createCustomer, updateCustomer };
