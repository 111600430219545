const CHECK_LIST_STATUS = {
    WAITING_FOR_EXAMINATION: 1,
    WAITING_FOR_PAYMENT: 2,
    WAITING_FOR_INJECTION: 3,
    WAITING_FOR_REACTION: 4,
    DONE: 5,
    DO_NOT_INJECT: 6,
    CANCEL: 7,
};

const checkListStatus = [
    {
        value: 11,
        label: "Tất cả",
    },
    {
        value: 1,
        label: "Chờ khám",
    },
    {
        value: 2,
        label: "Chờ thanh toán",
    },
    {
        value: 3,
        label: "Chờ tiêm",
    },
    {
        value: 4,
        label: "Chờ sau tiêm",
    },
    {
        value: 5,
        label: "Hoàn thành",
    },
    {
        value: 6,
        label: "Không tiêm",
    },
    {
        value: 7,
        label: "Hủy",
    },
];

const CHECK_LIST_STATUS_DEFAULT = 11;

const CHECK_LIST_TYPE = {
    INJECTION: "injection",
    SCHEDULED: "schedule",
};

const APPOINTMENT_HISTORY_STATUS = {
    8: {
        label: "Chờ",
        color: "primary",
    },
    9: {
        label: "Đã tiêm",
        color: "success",
    },
    10: {
        label: "Quá hạn",
        color: "error",
    },
    11: {
        label: "Tất cả",
        color: "error",
    },
};

const APPOINTMENT_HISTORY_STATUS_DEFAULT = 8;
const APPOINTMENT_HISTORY_ALL_STATUS = 11;

export {
    CHECK_LIST_STATUS,
    checkListStatus,
    CHECK_LIST_STATUS_DEFAULT,
    CHECK_LIST_TYPE,
    APPOINTMENT_HISTORY_STATUS,
    APPOINTMENT_HISTORY_STATUS_DEFAULT,
    APPOINTMENT_HISTORY_ALL_STATUS,
};
