import api from "./api";

const getAssignVaccines = async (checkListId) => {
    const response = await api({
        method: "GET",
        url: "/assign-vaccines",
        params: { checkListId },
    });

    return response;
};

export { getAssignVaccines };
