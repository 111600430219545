import { createSlice } from "@reduxjs/toolkit";
import { ALL_STORE } from "../constants";
import { STORE_STATUS } from "../constants/store";

const initialState = {
    currentStore: ALL_STORE,
    storeActive: STORE_STATUS.INACTIVE,
    stores: [],
};

export const storeSlice = createSlice({
    name: "store",
    initialState: initialState,
    reducers: {
        setCurrentStore: (state, action) => {
            return { ...state, currentStore: action.payload };
        },
        setStoreActive: (state, action) => {
            return { ...state, storeActive: action.payload };
        },
        setStores: (state, action) => {
            return { ...state, stores: [...action.payload] };
        },
    },
});

export const { setCurrentStore, setStoreActive, setStores } = storeSlice.actions;

export default storeSlice.reducer;
