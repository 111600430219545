import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import {
    APPOINTMENT_HISTORY_ALL_STATUS,
    APPOINTMENT_HISTORY_STATUS,
    APPOINTMENT_HISTORY_STATUS_DEFAULT,
} from "../../../constants/checkList";
import { COLOR } from "../../../styles/color";
import apis from "../../../apis";
import { handleCallApiError } from "../../../errors";
import Table from "../../../components/Table";
import debounce from "../../../utils/debounce";
import { ALL_STORE, PAGINATION_LIMIT } from "../../../constants";
import CreateCheckListDialog from "../../CreateCheckListDialog";
import { INIT_ASSIGN_VACCINE, INIT_ASSIGN_VACCINE_ERROR } from "../../../constants/vaccines";

const ScheduleList = ({ search, status, date, paging, onChangePage }) => {
    const [appointmentsHistory, setAppointmentsHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [registrationDialog, setRegistrationDialog] = useState(false);
    const [checkList, setCheckList] = useState({});
    const [vaccines, setVaccines] = useState([INIT_ASSIGN_VACCINE]);
    const [vaccinesError, setVaccinesError] = useState([INIT_ASSIGN_VACCINE_ERROR]);
    const currentStore = useSelector((state) => state.store.currentStore);

    const initAssignVaccinesError = (count) => {
        let errors = [];
        for (let index = 0; index < count; index++) {
            errors.push(INIT_ASSIGN_VACCINE_ERROR);
        }
        return errors;
    };

    const handleOpenRegistrationDialog = (row) => {
        setCheckList(row);
        const assignVaccines = row.vaccines.map((vaccine) => ({
            vaccineId: vaccine.detail.vaccineId,
            numberOfInjections: vaccine.detail.numberOfInjections,
            dosage: "none",
        }));

        if (assignVaccines.length > 0) {
            setVaccines(assignVaccines);
            setVaccinesError(initAssignVaccinesError(assignVaccines.length));
        }
        setRegistrationDialog(true);
    };

    const handleCloseRegistrationDialog = () => {
        setRegistrationDialog(false);
        setCheckList({});
        setVaccines([INIT_ASSIGN_VACCINE]);
        setVaccinesError([INIT_ASSIGN_VACCINE_ERROR]);
        fetchAppointmentsHistory();
    };

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const fetchAppointmentsHistory = async () => {
        setLoading(true);
        try {
            const [start, end] = date;
            const condition = {
                search,
                start,
                end,
                status: status === APPOINTMENT_HISTORY_ALL_STATUS ? null : status,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            };
            if (currentStore !== ALL_STORE) condition.storeId = currentStore;
            const data = await apis.checkList.getCustomerAppointmentsHistory(condition);
            setAppointmentsHistory(data?.appointmentsHistory);
            onChangePage({ ...paging, total: data?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        debounce(fetchAppointmentsHistory, 500)(search);
    }, [paging.page, status, search, date, currentStore]);

    const columns = [
        {
            title: "Trạng thái",
            align: "center",
            render: (row) => (
                <Typography color={APPOINTMENT_HISTORY_STATUS[row.status].color}>
                    {APPOINTMENT_HISTORY_STATUS[row.status].label}
                </Typography>
            ),
        },
        {
            title: "Ngày hẹn",
            align: "center",
            render: (row) => dayjs(row.schedule).format("DD/MM/YYYY"),
        },
        {
            title: "Họ và tên",
            align: "left",
            render: (row) => (
                <>
                    <Typography color="primary" fontSize="13px">
                        {row.customer.name}
                    </Typography>
                    <Typography fontSize="13px">{row.customer.id}</Typography>
                </>
            ),
        },
        {
            title: "SĐT",
            align: "center",
            render: (row) => row.customer.phoneNumber,
        },
        {
            title: "Vắc xin",
            align: "left",
            render: (row) => `${row.vaccines[0].name} | ${row.vaccines[0].desc}`,
        },
        {
            title: "Ngày tạo",
            align: "left",
            render: (row) => dayjs(row.createdAt).format("DD/MM/YYYY"),
        },
        {
            title: "Ghi chú",
            align: "left",
            render: (row) => row.vaccines[0].detail.note,
        },
        {
            title: "...",
            align: "center",
            render: (row) =>
                row.status === APPOINTMENT_HISTORY_STATUS_DEFAULT && (
                    <Box display="flex" justifyContent="center">
                        <Tooltip title="Tạo phiếu">
                            <IconButton onClick={() => handleOpenRegistrationDialog(row)}>
                                <AddCircleOutline sx={{ color: COLOR.primary }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
        },
    ];

    return (
        <>
            <Table
                data={appointmentsHistory}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
            />
            <CreateCheckListDialog
                handleCloseRegistrationDialog={handleCloseRegistrationDialog}
                registrationDialog={registrationDialog}
                customer={checkList.customer}
                initVaccine={vaccines}
                initVaccineError={vaccinesError}
                scheduleId={checkList.id}
            />
        </>
    );
};

export default ScheduleList;
