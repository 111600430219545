import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, FastField } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { LoginWrapper } from "./styles";
import CustomInput from "../../components/CustomInput";
import Copyright from "../../components/Copyright";

import apis from "../../apis";
import { login } from "../../stores/authSlice";
import { handleCallApiError } from "../../errors";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const initialValues = {
        email: "",
        password: "",
    };

    const validationSchema = Yup.object({
        email: Yup.string().email("Email không đúng định dạng").required("Email không được để trống"),
        password: Yup.string().required("Mật khẩu không được để trống"),
    });

    const handleSubmit = async ({ email, password }) => {
        setLoading(true);
        try {
            const res = await apis.auth.login(email, password);
            setLoading(false);
            dispatch(login(res.token));
            toast.success("Đăng nhập thành công");
        } catch (error) {
            setLoading(false);
            handleCallApiError(error);
        }
    };

    return (
        <>
            <LoginWrapper>
                <Typography variant="h4" component="h1" className="login-title">
                    Đăng nhập
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => await handleSubmit(values)}
                >
                    {(props) => {
                        return (
                            <Form>
                                <FastField
                                    name="email"
                                    component={CustomInput}
                                    label="Email"
                                    type="text"
                                    disable={false}
                                />
                                <FastField
                                    name="password"
                                    component={CustomInput}
                                    label="Mật khẩu"
                                    type="password"
                                    disable={false}
                                />
                                <LoadingButton
                                    size="small"
                                    type="submit"
                                    loading={loading}
                                    variant="outlined"
                                    fullWidth
                                >
                                    Đăng nhập
                                </LoadingButton>
                            </Form>
                        );
                    }}
                </Formik>
            </LoginWrapper>
            <Box
                component="footer"
                sx={{
                    p: 2,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    bgcolor: "#ffffff",
                }}
            >
                <Copyright />
            </Box>
        </>
    );
};

export default Login;
