const USER_STATUS = {
    ACTIVE: "active",
    INACTIVE: "inactive",
};

const INIT_USER_ERROR = {
    name: false,
    email: false,
    dateOfBirth: false,
    phoneNumber: false,
    roleId: false,
    storeId: false,
};

const INIT_USER = {
    name: "",
    email: "",
    dateOfBirth: null,
    phoneNumber: "",
    roleId: "",
    storeId: "",
};

export { USER_STATUS, INIT_USER_ERROR, INIT_USER };
