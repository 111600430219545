import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentRole: null,
    permissions: [],
};

export const roleSlice = createSlice({
    name: "role",
    initialState: initialState,
    reducers: {
        setCurrentRole: (state, action) => {
            return { ...state, currentRole: action.payload };
        },
        setPermissions: (state, action) => {
            return { ...state, permissions: [...action.payload] };
        },
    },
});

export const { setCurrentRole, setPermissions } = roleSlice.actions;

export default roleSlice.reducer;
