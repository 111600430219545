import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Add, Delete } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { StyledSchedule } from "../index.style";
import { handleCallApiError } from "../../../errors";
import apis from "../../../apis";
import { INIT_SCHEDULE_VACCINE, INIT_SCHEDULE_VACCINE_ERROR, NUMBER_OF_INJECTION } from "../../../constants/vaccines";
import {
    APPOINTMENT_HISTORY_ALL_STATUS,
    CHECK_LIST_TYPE,
    APPOINTMENT_HISTORY_STATUS,
} from "../../../constants/checkList";
import AppointmentsHistory from "./AppointmentsHistory";
import { ALL_STORE } from "../../../constants";

const INITIAL_PAGING = { page: 1, total: 0 };

const Schedule = () => {
    const [scheduleVaccines, setScheduleVaccines] = useState([INIT_SCHEDULE_VACCINE]);
    const [scheduleVaccinesError, setScheduleVaccinesError] = useState([INIT_SCHEDULE_VACCINE_ERROR]);
    const [saving, setSaving] = useState(false);
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [status, setStatus] = useState(APPOINTMENT_HISTORY_ALL_STATUS);
    const [reload, setReload] = useState(false);

    const vaccines = useSelector((state) => state.vaccine);
    const checkList = useSelector((state) => state.checkList);
    const currentStore = useSelector((state) => state.store.currentStore);

    const handleChangeVaccine = (newValue, index) => {
        let vaccines = [...scheduleVaccines];
        vaccines[index] = { ...vaccines[index], vaccineId: newValue ? parseInt(newValue) : null };
        setScheduleVaccines(vaccines);
        handleChangeError(index, { vaccineId: false });
    };

    const handleChangeNumberOfInjections = (event, index) => {
        let vaccines = [...scheduleVaccines];
        vaccines[index] = { ...vaccines[index], numberOfInjections: event.target.value };
        setScheduleVaccines(vaccines);
    };

    const handleChangeSchedule = (newValue, index) => {
        let vaccines = [...scheduleVaccines];
        vaccines[index] = { ...vaccines[index], schedule: newValue };
        setScheduleVaccines(vaccines);
        handleChangeError(index, { schedule: false });
    };

    const handleChangeNote = (event, index) => {
        let vaccines = [...scheduleVaccines];
        vaccines[index] = { ...vaccines[index], note: event.target.value };
        setScheduleVaccines(vaccines);
    };

    const handleChangeError = (index, obj) => {
        let vaccinesError = [...scheduleVaccinesError];
        vaccinesError[index] = { ...vaccinesError[index], ...obj };
        setScheduleVaccinesError(vaccinesError);
    };

    const handleDeleteVaccine = (index) => {
        let vaccines = [...scheduleVaccines];
        vaccines.splice(index, 1);
        setScheduleVaccines([...vaccines]);
        let vaccinesError = [...scheduleVaccinesError];
        vaccinesError.splice(index, 1);
        setScheduleVaccinesError(vaccinesError);
    };

    const handleAddVaccine = () => {
        setScheduleVaccines((prev) => [...prev, INIT_SCHEDULE_VACCINE]);
        setScheduleVaccinesError((prev) => [...prev, INIT_SCHEDULE_VACCINE_ERROR]);
    };

    const validate = () => {
        let isSuccess = true;

        let vaccinesError = [...scheduleVaccinesError];

        vaccinesError = vaccinesError.map((vaccineError, index) => {
            let error = { ...vaccineError };

            if (scheduleVaccines[index].vaccineId === null) {
                isSuccess = false;
                error = { ...error, vaccineId: true };
            }

            if (scheduleVaccines[index].schedule === null) {
                isSuccess = false;
                error = { ...error, schedule: true };
            }
            return error;
        });

        setScheduleVaccinesError(vaccinesError);

        return isSuccess;
    };

    const formatSchedule = (scheduleVaccines) => {
        return scheduleVaccines.map((vaccine) => ({
            ...vaccine,
            schedule: dayjs(vaccine.schedule).format("YYYY-MM-DD"),
        }));
    };

    const handleSave = async () => {
        if (!validate()) {
            toast.error("Vui lòng điền đầy đủ thông tin");
            return;
        }

        if (currentStore === ALL_STORE) {
            toast.warn("Vui lòng chỉ định cửa hàng!");
            return;
        }

        setSaving(true);
        try {
            await apis.checkList.createCheckList({
                customerId: checkList?.customerId,
                type: CHECK_LIST_TYPE.SCHEDULED,
                vaccines: formatSchedule(scheduleVaccines),
                storeId: currentStore,
            });
            setReload((prev) => !prev);
            setScheduleVaccines([INIT_SCHEDULE_VACCINE]);
            setScheduleVaccinesError([INIT_SCHEDULE_VACCINE]);
            setStatus(APPOINTMENT_HISTORY_ALL_STATUS);
            setPaging(INITIAL_PAGING);
            toast.success("Lưu lịch hẹn thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setSaving(false);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleChangeStatus = (event) => {
        setStatus(parseInt(event.target.value, 10));
    };

    const renderVaccineName = (key) => {
        const vaccine = vaccines.find((vc) => vc.id === key);
        return `${vaccine.name} | ${vaccine.desc}`;
    };

    return (
        <StyledSchedule>
            <Box mb={1}>
                <Typography className="injection-service">Chỉ định vắc xin</Typography>
                <Box mb={3}>
                    {scheduleVaccines.map((vaccine, index) => (
                        <Grid
                            key={index}
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            marginBottom={1}
                        >
                            <Grid item xs={2} sm={4} md={4}>
                                <Typography fontWeight={500} mb={1}>
                                    Vắc xin:
                                </Typography>
                                <Autocomplete
                                    size="small"
                                    value={vaccine.vaccineId}
                                    options={vaccines.map((vaccine) => vaccine.id)}
                                    getOptionLabel={(option) => renderVaccineName(option)}
                                    filterSelectedOptions
                                    onChange={(event, newValue) => handleChangeVaccine(newValue, index)}
                                    renderOption={(props, key) => (
                                        <MenuItem value={key} {...props}>
                                            {renderVaccineName(key)}
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} error={scheduleVaccinesError[index]?.vaccineId} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={1.5}>
                                <Typography fontWeight={500} mb={1}>
                                    Mũi:
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={vaccine.numberOfInjections}
                                        size="small"
                                        onChange={(event) => handleChangeNumberOfInjections(event, index)}
                                    >
                                        {NUMBER_OF_INJECTION.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={4} md={2.5}>
                                <Typography fontWeight={500} mb={1}>
                                    Ngày hẹn:
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={dayjs().add(1, "d")}
                                        value={vaccine.schedule}
                                        onChange={(value) => handleChangeSchedule(value, index)}
                                        inputFormat="DD/MM/YYYY"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                error={scheduleVaccinesError[index].schedule}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} sm={4} md={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Ghi chú:
                                </Typography>
                                <TextField
                                    value={vaccine.note}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeNote(event, index)}
                                />
                            </Grid>
                            <Grid item xs={2} sm={4} md={1} sx={{ textAlign: "end" }} alignSelf="flex-end">
                                {scheduleVaccines.length > 1 && (
                                    <Tooltip title="Xóa vắc xin">
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() => handleDeleteVaccine(index)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Button variant="outlined" startIcon={<Add />} onClick={handleAddVaccine}>
                    Thêm
                </Button>
            </Box>
            <Stack direction="row" justifyContent="flex-end" spacing={1} mb={2}>
                <LoadingButton variant="contained" onClick={handleSave} loading={saving}>
                    Lưu lịch hẹn
                </LoadingButton>
            </Stack>
            <Divider />
            <Box mt={2}>
                <Typography className="schedule-history">Lịch hẹn gần đây</Typography>
            </Box>
            <Stack direction="row" justifyContent="flex-end">
                <FormControl sx={{ minWidth: 150, mb: 1 }} size="small">
                    <Select value={status} onChange={handleChangeStatus}>
                        {Object.keys(APPOINTMENT_HISTORY_STATUS).map((key) => (
                            <MenuItem key={key} value={key}>
                                {APPOINTMENT_HISTORY_STATUS[key].label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <AppointmentsHistory status={status} paging={paging} onChangePage={handleChangePaging} reload={reload} />
        </StyledSchedule>
    );
};

export default Schedule;
