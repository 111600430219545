import React from "react";

const Dashboard = () => {
    return (
        <div style={{ padding: 20 }}>
            <h1>Dashboard</h1>
            <ol style={{ paddingLeft: 30 }}>
                <li style={{ color: "#00e600" }}>Refactor</li>
                <li style={{ color: "#00e600" }}>CRUD store</li>
                <li style={{ color: "#00e600" }}>CRUD vaccine</li>
                <li style={{ color: "#00e600" }}>Warehouse</li>
                <li>CRUD user</li>
                <li>Authorize</li>
                <li>Dashboard chart</li>
                <li>Profile</li>
            </ol>
        </div>
    );
};

export default Dashboard;
