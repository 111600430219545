import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dialog from "../../components/Dialog";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { INITIAL_CUSTOMER_ERROR, INITIAL_CUSTOMER } from "../../constants/customer";

const CreateCustomerDialog = ({
    handleCloseCustomerDialog,
    customerDialog,
    customer,
    setCustomer,
    handleReload,
    dialogType,
}) => {
    const [customerError, setCustomerError] = useState(INITIAL_CUSTOMER_ERROR);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const handleChangeField = (event, field) => {
        const { value } = event.target;
        setCustomer((prev) => ({ ...prev, [field]: value }));
        setCustomerError((prev) => ({ ...prev, [field]: false }));
    };

    const handleChangeDateOfBirth = (newValue) => {
        setCustomer((prev) => ({ ...prev, dateOfBirth: newValue }));
        setCustomerError((prev) => ({ ...prev, dateOfBirth: false }));
    };

    const handleChangeProvince = async (newValue) => {
        setCustomer((prev) => ({ ...prev, provinceId: newValue }));
        setCustomerError((prev) => ({ ...prev, provinceId: false }));
        if (newValue) {
            await fetchDistricts(newValue);
        } else {
            setDistricts([]);
            setWards([]);
            setCustomer((prev) => ({ ...prev, districtId: null, wardId: null }));
        }
    };

    const handleChangeDistrict = async (newValue) => {
        setCustomer((prev) => ({ ...prev, districtId: newValue }));
        setCustomerError((prev) => ({ ...prev, districtId: false }));
        if (newValue) {
            await fetchWards(newValue);
        } else {
            setWards([]);
            setCustomer((prev) => ({ ...prev, wardId: null }));
        }
    };

    const handleChangeWard = async (newValue) => {
        setCustomer((prev) => ({ ...prev, wardId: newValue }));
        setCustomerError((prev) => ({ ...prev, wardId: false }));
    };

    const renderName = (type, id) => {
        switch (type) {
            case "province":
                const province = provinces.find((p) => p.id === id);
                return province?.name || "Đang tải...";
            case "district":
                const district = districts.find((p) => p.id === id);
                return district?.name || "Đang tải...";
            case "ward":
                const ward = wards.find((p) => p.id === id);
                return ward?.name || "Đang tải...";
            default:
                return "Đang tải...";
        }
    };

    const fetchProvinces = async () => {
        setLoading(true);
        try {
            const res = await apis.address.getProvinces();
            setProvinces(res?.provinces);
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const fetchDistricts = async (provinceId) => {
        setAdding(true);
        try {
            const res = await apis.address.getDistricts(provinceId);
            setDistricts(res?.districts);
        } catch (error) {
            handleCallApiError(error);
        }
        setAdding(false);
    };

    const fetchWards = async (districtId) => {
        setAdding(true);
        try {
            const res = await apis.address.getWards(districtId);
            setWards(res?.wards);
        } catch (error) {
            handleCallApiError(error);
        }
        setAdding(false);
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...customerError };
        Object.keys(customer).forEach((key) => {
            if (customer[key] === INITIAL_CUSTOMER[key] && key !== "gender") {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setCustomerError(error);
        return isSuccess;
    };

    const handleCreateCustomer = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            let { dateOfBirth, ...updateField } = customer;
            dateOfBirth = dayjs(dateOfBirth).format("DD/MM/YYYY");
            await apis.customer.createCustomer({ ...updateField, dateOfBirth });
            setCustomer(INITIAL_CUSTOMER);
            setCustomerError(INITIAL_CUSTOMER_ERROR);
            handleCloseCustomerDialog();
            handleReload();
            toast.success("Thêm khách hàng thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleUpdateCustomer = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            let { id: customerId, dateOfBirth, ...updateField } = customer;
            if (typeof dateOfBirth !== "string") dateOfBirth = dayjs(dateOfBirth).format("DD/MM/YYYY");
            const { status } = await apis.customer.updateCustomer(customerId, { ...updateField, dateOfBirth });
            if (status === 1) {
                toast.success("Cập nhật thông tin thành công");
            } else {
                toast.error("Cập nhật thông tin thất bại");
            }
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleClose = () => {
        handleCloseCustomerDialog();
        setDistricts([]);
        setWards([]);
    };

    useEffect(() => {
        fetchProvinces();
    }, []);

    useEffect(() => {
        if (customer?.provinceId) fetchDistricts(customer?.provinceId);
    }, [customer?.provinceId]);

    useEffect(() => {
        if (customer?.districtId) fetchWards(customer?.districtId);
    }, [customer?.districtId]);

    return (
        <Dialog
            title={dialogType === "create" ? "Thêm khách hàng mới" : "Cập nhật thông tin khách hàng"}
            maxWidth="md"
            onClose={handleClose}
            open={customerDialog}
        >
            <DialogContentText sx={{ padding: "10px 20px" }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    Object.keys(customer).length > 0 && (
                        <Box mb={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography fontWeight={500} mb={1}>
                                        Tên khách hàng:
                                    </Typography>
                                    <TextField
                                        error={customerError.name}
                                        value={customer.name}
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChangeField(event, "name")}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Giới tính:
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            value={customer.gender}
                                            onChange={(event) => handleChangeField(event, "gender")}
                                        >
                                            <FormControlLabel value={0} control={<Radio />} label="Nam" />
                                            <FormControlLabel value={1} control={<Radio />} label="Nữ" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Ngày sinh:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            maxDate={dayjs().subtract(1, "d")}
                                            value={
                                                customer.dateOfBirth ? dayjs(customer.dateOfBirth, "DD/MM/YYYY") : null
                                            }
                                            onChange={(value) => handleChangeDateOfBirth(value)}
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    fullWidth
                                                    error={customerError.dateOfBirth}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Tên phụ huynh:
                                    </Typography>
                                    <TextField
                                        error={customerError.parentName}
                                        value={customer.parentName}
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChangeField(event, "parentName")}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Số điện thoại:
                                    </Typography>
                                    <TextField
                                        error={customerError.phoneNumber}
                                        value={customer.phoneNumber}
                                        size="small"
                                        fullWidth
                                        onChange={(event) => handleChangeField(event, "phoneNumber")}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Tỉnh/Thành phố:
                                    </Typography>
                                    <Autocomplete
                                        size="small"
                                        value={customer.provinceId}
                                        options={provinces.map((province) => province.id)}
                                        getOptionLabel={(option) => renderName("province", option)}
                                        filterSelectedOptions
                                        onChange={(event, newValue) => handleChangeProvince(newValue)}
                                        renderOption={(props, key) => (
                                            <MenuItem value={key} {...props}>
                                                {renderName("province", key)}
                                            </MenuItem>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} error={customerError.provinceId} fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Huyện/Thị xã:
                                    </Typography>
                                    <Autocomplete
                                        size="small"
                                        value={customer.districtId}
                                        options={districts.map((district) => district.id)}
                                        getOptionLabel={(option) => renderName("district", option)}
                                        filterSelectedOptions
                                        onChange={(event, newValue) => handleChangeDistrict(newValue)}
                                        renderOption={(props, key) => (
                                            <MenuItem value={key} {...props}>
                                                {renderName("district", key)}
                                            </MenuItem>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} error={customerError.districtId} fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={500} mb={1}>
                                        Xã/Phường:
                                    </Typography>
                                    <Autocomplete
                                        size="small"
                                        value={customer.wardId}
                                        options={wards.map((ward) => ward.id)}
                                        getOptionLabel={(option) => renderName("ward", option)}
                                        filterSelectedOptions
                                        onChange={(event, newValue) => handleChangeWard(newValue)}
                                        renderOption={(props, key) => (
                                            <MenuItem value={key} {...props}>
                                                {renderName("ward", key)}
                                            </MenuItem>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} error={customerError.wardId} fullWidth />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                )}
            </DialogContentText>
            <Divider />
            <DialogActions>
                {adding && (
                    <Box mr={1} pt={0.8}>
                        <CircularProgress size={20} />
                    </Box>
                )}
                <Button variant="outlined" color="secondary" onClick={handleCloseCustomerDialog}>
                    Hủy
                </Button>
                {dialogType === "create" ? (
                    <LoadingButton variant="contained" color="success" loading={waiting} onClick={handleCreateCustomer}>
                        Lưu khách hàng
                    </LoadingButton>
                ) : (
                    <LoadingButton variant="contained" color="success" loading={waiting} onClick={handleUpdateCustomer}>
                        Lưu thay đổi
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CreateCustomerDialog;
