import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContentText, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import AssignInjection from '../../components/AssignInjection';
import Dialog from '../../components/Dialog';
import { CHECK_LIST_TYPE } from '../../constants/checkList';
import { INIT_ASSIGN_VACCINE, INIT_ASSIGN_VACCINE_ERROR } from '../../constants/vaccines';
import { generateAge } from '../../utils/date';
import { INJECTION_TYPE, INJECTION_TYPE_DEFAULT } from '../Receptionist/Customers/config';
import apis from "../../apis";
import { handleCallApiError } from '../../errors';
import { useEffect } from 'react';
import { ALL_STORE } from '../../constants';

const CreateCheckListDialog = ({handleCloseRegistrationDialog, registrationDialog, customer, initVaccine, initVaccineError, scheduleId }) => {
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState("");
    const [assignVaccines, setAssignVaccines] = useState();
    const [assignVaccinesError, setAssignVaccinesError] = useState();
    const currentStore = useSelector((state) => state.store.currentStore);

    const handleChangeNote = (event) => {
        const { value } = event.target;
        setNote(value);
    };

    const handleCloseDialog = () => {
        setNote("");
        setAssignVaccines([INIT_ASSIGN_VACCINE]);
        setAssignVaccinesError([INIT_ASSIGN_VACCINE_ERROR]);
        handleCloseRegistrationDialog();
    }

    const validateResult = () => {
        let isSuccess = true;
        let vaccinesError = [...assignVaccinesError];
        vaccinesError = vaccinesError.map((vaccineError, index) => {
            let error = { ...vaccineError };
            if (!(assignVaccines[index].vaccineId === null && assignVaccines[index].dosage === "none")) {
                if (assignVaccines[index].vaccineId === null) {
                    isSuccess = false;
                    error = { ...error, vaccineId: true };
                }

                if (assignVaccines[index].dosage === "none") {
                    isSuccess = false;
                    error = { ...error, dosage: true };
                }
            }

            return error;
        });
        setAssignVaccinesError(vaccinesError);
        return isSuccess;
    };

    const handleRegistration = async () => {
        if (!validateResult()) {
            toast.error("Vui lòng điền đầy đủ thông tin vắc-xin chỉ định");
            return;
        }

        if (currentStore === ALL_STORE) {
             toast.warn("Vui lòng chỉ định cửa hàng!");
            return;
        }

        setLoading(true);
        try {
            const data = await apis.checkList.createCheckList({
                customerId: customer.id,
                type: CHECK_LIST_TYPE.INJECTION,
                note,
                vaccines: assignVaccines.filter(
                    (vaccine) => !(vaccine.vaccineId === null) && !(vaccine.dosage === "none")
                ),
                scheduleId,
                storeId: currentStore
            });

            handleCloseDialog();
            toast.success(`Tạo phiếu thành công, Số thứ tự là ${data?.checkList?.order}`);
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setAssignVaccines(initVaccine || [INIT_ASSIGN_VACCINE]);
        setAssignVaccinesError(initVaccineError || [INIT_ASSIGN_VACCINE_ERROR]);
    }, [initVaccine, initVaccineError ]);

    return (
        <Dialog
                title="Tạo phiếu đăng ký"
                maxWidth="lg"
                onClose={handleCloseDialog}
                open={registrationDialog}
            >
                <DialogContentText sx={{ padding: "10px 20px" }}>
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <Typography fontWeight={500} marginRight={2}>
                            Hình thức tiêm:
                        </Typography>
                        <FormControl>
                            <RadioGroup row value={INJECTION_TYPE_DEFAULT}>
                                {INJECTION_TYPE.map((type) => (
                                    <FormControlLabel
                                        key={type.key}
                                        value={type.key}
                                        control={<Radio />}
                                        label={type.value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box marginBottom={3}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Họ và tên:
                                    </Typography>
                                    <Typography>{customer?.name}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Giới tính:
                                    </Typography>
                                    <Typography>{!customer?.gender ? "Nam" : "Nữ"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Ngày sinh:
                                    </Typography>
                                    <Typography>{customer?.dateOfBirth}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Tuổi:
                                    </Typography>
                                    <Typography>{generateAge(customer?.dateOfBirth)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Phụ huynh:
                                    </Typography>
                                    <Typography>{customer?.parentName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                                <Box display="flex">
                                    <Typography fontWeight={500} marginRight={1}>
                                        Số điện thoại:
                                    </Typography>
                                    <Typography>{customer?.phoneNumber}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={1}>
                        <AssignInjection
                            assignVaccines={assignVaccines}
                            setAssignVaccines={setAssignVaccines}
                            assignVaccinesError={assignVaccinesError}
                            setAssignVaccinesError={setAssignVaccinesError}
                            canEdit={true}
                        />
                    </Box>
                    <Box>
                        <Typography fontWeight={500} marginBottom={1}>
                            Ghi chú:
                        </Typography>
                        <TextField
                            value={note}
                            size="small"
                            fullWidth
                            multiline={true}
                            onChange={handleChangeNote}
                            rows={2}
                        />
                    </Box>
                </DialogContentText>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                        Hủy
                    </Button>
                    <LoadingButton variant="contained" color="success" onClick={handleRegistration} loading={loading}>
                        Lưu phiếu
                    </LoadingButton>
                </DialogActions>
            </Dialog>
    );
};

export default CreateCheckListDialog;
