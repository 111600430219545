import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Search } from "@mui/icons-material";
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import { StyledRegistered } from "./index.style";
import { checkListStatus, CHECK_LIST_STATUS_DEFAULT } from "../../../constants/checkList";
import RegisteredList from "./RegisteredList";

const INITIAL_PAGING = { page: 1, total: 0 };

const Registered = () => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(CHECK_LIST_STATUS_DEFAULT);
    const [paging, setPaging] = useState(INITIAL_PAGING);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangeStatus = (event) => {
        const { value } = event.target;
        setStatus(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    return (
        <StyledRegistered>
            <div className="filter">
                <div className="search">
                    <TextField
                        value={search}
                        size="small"
                        fullWidth
                        placeholder={"Tìm kiếm theo Mã tiềm chủng/Họ tên/Tên người bảo hộ/CMND/Số điện thoại"}
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box>
                        <FormControl>
                            <InputLabel id="status-select-label">Trạng thái</InputLabel>
                            <Select
                                className="status-select"
                                labelId="status-select-label"
                                value={status}
                                onChange={handleChangeStatus}
                                size="small"
                                label="Trạng thái"
                            >
                                {checkListStatus.map((status) => (
                                    <MenuItem key={uuidv4()} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <Box></Box>
            </div>
            <RegisteredList search={search} status={status} paging={paging} onChangePage={handleChangePaging} />
        </StyledRegistered>
    );
};

export default Registered;
