import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";

import { handleCallApiError } from "../../errors";
import Table from "../../components/Table";
import apis from "../../apis";
import { Add, Delete, Edit } from "@mui/icons-material";
import { COLOR } from "../../styles/color";
import { PAGINATION_LIMIT } from "../../constants";
import Dialog from "../../components/Dialog";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { PERMISSION_TYPE } from "../../constants/permission";
import PermissionTypeTag from "../../components/PermissionTypeTag";

const INITIAL_PAGING = { page: 1, total: 0 };

const INT_PERMISSION_ERROR = {
    name: false,
    path: false,
    type: false,
};

const INT_PERMISSION = {
    name: "",
    path: "",
    type: "",
};

const PermissionList = ({ selectedGroupPermissionId }) => {
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [loading, setLoading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [permission, setPermission] = useState(INT_PERMISSION);
    const [errorPermission, setErrorPermission] = useState(INT_PERMISSION_ERROR);
    const [dialogType, setDialogType] = useState("create");
    const [openDialog, setOpenDialog] = useState(false);

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleChangePage = (page) => handleChangePaging({ ...paging, page });

    const fetchPermissions = async () => {
        setLoading(true);
        try {
            const res = await apis.permission.getPermissions({
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
                groupId: selectedGroupPermissionId,
            });
            setPermissions(res.permissions);
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedGroupPermissionId) fetchPermissions();
    }, [selectedGroupPermissionId]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setErrorPermission(INT_PERMISSION_ERROR);
        setPermission(INT_PERMISSION);
    };

    const handChangePermission = (event, field) => {
        const value = event.target.value;
        setPermission((prev) => ({ ...prev, [field]: value }));
        setErrorPermission((prev) => ({ ...prev, [field]: false }));
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...errorPermission };
        Object.keys(INT_PERMISSION_ERROR).forEach((key) => {
            if (permission[key] === INT_PERMISSION[key]) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setErrorPermission(error);
        return isSuccess;
    };

    const handleCreatePermission = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.permission.createPermission({ ...permission, groupId: selectedGroupPermissionId });
            handleCloseDialog();
            fetchPermissions();
            toast.success("Thêm quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleUpdateGroupPermission = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setWaiting(true);
        try {
            await apis.permission.updatePermission(permission.id, { ...permission });
            handleCloseDialog();
            fetchPermissions();
            toast.success("Cập nhật quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
        setWaiting(false);
    };

    const handleDeleteGroupPermission = async (permissionId) => {
        try {
            await apis.permission.deletePermission(permissionId);
            fetchPermissions();
            toast.success("Xóa quyền thành công");
        } catch (error) {
            handleCallApiError(error);
        }
    };

    const handleOpenDialog = (type, object) => {
        setDialogType(type);
        if (object) setPermission(object);
        setOpenDialog(true);
    };

    const columns = [
        {
            field: "name",
            title: "Tên quyền",
            align: "left",
        },
        {
            field: "path",
            title: "Đường dẫn",
            align: "left",
        },
        {
            title: "Loại quyền",
            align: "left",
            render: (row) => <PermissionTypeTag type={row.type} />,
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Stack direction="row" justifyContent="center">
                    <Tooltip title="Chỉnh sứa">
                        <IconButton onClick={() => handleOpenDialog("update", row)}>
                            <Edit sx={{ color: COLOR.purple }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Xóa">
                        <IconButton onClick={() => handleDeleteGroupPermission(row.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
        },
    ];

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={1}>
                <Typography variant="h6">Danh sách quyền</Typography>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<Add />}
                    onClick={() => handleOpenDialog("create")}
                >
                    Thêm quyền
                </Button>
            </Stack>

            <Table
                data={permissions}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
                showNumber={true}
            />
            <Dialog
                title={dialogType === "create" ? "Thêm nhóm quyền" : "Cập nhật nhóm quyền"}
                maxWidth="sm"
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogContent>
                    {permission && (
                        <Box>
                            <Box mb={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Tên quyền:
                                </Typography>
                                <TextField
                                    error={errorPermission.name}
                                    value={permission.name}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handChangePermission(event, "name")}
                                    placeholder="Tên quyền"
                                />
                            </Box>
                            <Box mb={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Đường dẫn:
                                </Typography>
                                <TextField
                                    error={errorPermission.path}
                                    value={permission.path}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handChangePermission(event, "path")}
                                    placeholder="Đường dẫn"
                                />
                            </Box>
                            <Box mb={3}>
                                <Typography fontWeight={500} mb={1}>
                                    Loại quyền:
                                </Typography>
                                <FormControl fullWidth error={errorPermission.type}>
                                    <Select
                                        value={permission.type}
                                        size="small"
                                        onChange={(event) => handChangePermission(event, "type")}
                                    >
                                        {Object.keys(PERMISSION_TYPE).map((key) => (
                                            <MenuItem key={PERMISSION_TYPE[key]} value={PERMISSION_TYPE[key]}>
                                                {PERMISSION_TYPE[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                        Hủy
                    </Button>
                    {dialogType === "create" ? (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            loading={waiting}
                            onClick={handleCreatePermission}
                        >
                            Thêm mới
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            loading={waiting}
                            onClick={handleUpdateGroupPermission}
                        >
                            Lưu thay đổi
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PermissionList;
