import React, { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { STORE_STATUS } from "../../constants/store";
import { COLOR } from "../../styles/color";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import VaccineDialog from "./VaccineDialog";
import { PAGINATION_LIMIT } from "../../constants";
import { formatMoney } from "../../utils/money";

const VaccineList = ({ search, paging, onChangePage, reload }) => {
    const [vaccines, setVaccines] = useState([]);
    const [vaccine, setVaccine] = useState({});
    const [loading, setLoading] = useState(false);
    const [vaccineDialog, setVaccineDialog] = useState(false);

    const handleChangePage = (page) => onChangePage({ ...paging, page });

    const handleOpenVaccineDialog = (row) => {
        setVaccine(row);
        setVaccineDialog(true);
    };

    const handleCloseVaccineDialog = () => {
        setVaccineDialog(false);
    };

    const fetchVaccines = async () => {
        setLoading(true);
        try {
            const res = await apis.vaccine.getVaccines({
                search,
                limit: PAGINATION_LIMIT,
                offset: (paging.page - 1) * PAGINATION_LIMIT,
            });
            setVaccines(res.vaccines);
            onChangePage({ ...paging, total: res?.total || 0 });
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const renderStatus = (status) => {
        switch (status) {
            case STORE_STATUS.ACTIVE:
                return (
                    <Typography sx={{ color: COLOR.success }} fontSize="13px">
                        Sử dụng
                    </Typography>
                );

            case STORE_STATUS.INACTIVE:
                return (
                    <Typography sx={{ color: COLOR.danger }} fontSize="13px">
                        Không sử dụng
                    </Typography>
                );

            default:
                break;
        }
    };

    const columns = [
        {
            field: "name",
            title: "Tên vắc xin",
            align: "left",
        },
        {
            field: "desc",
            title: "Mô tả",
            align: "left",
        },
        {
            field: "origin",
            title: "Xuất xứ",
            align: "left",
        },
        {
            field: "method",
            title: "Hình thức tiêm",
            align: "center",
        },
        {
            field: "dosage",
            title: "Liều lượng",
            align: "left",
        },
        {
            title: "Giá niêm yết",
            align: "right",
            render: (row) => formatMoney(row.price),
        },
        {
            title: "Giá nhập",
            align: "right",
            render: (row) => formatMoney(row.cost),
        },
        {
            title: "Trạng thái",
            align: "center",
            render: (row) => renderStatus(row.status),
        },
        {
            title: "...",
            align: "center",
            render: (row) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip title="Chỉnh sứa">
                        <IconButton onClick={() => handleOpenVaccineDialog(row)}>
                            <Edit sx={{ color: COLOR.purple }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ),
        },
    ];

    useEffect(() => {
        debounce(fetchVaccines, 500)(search);
    }, [paging.page, search, reload]);

    return (
        <>
            <Table
                data={vaccines}
                columns={columns}
                total={paging.total}
                page={paging.page}
                loading={loading}
                onChangePage={handleChangePage}
                showNumber={true}
            />
            <VaccineDialog
                open={vaccineDialog}
                handleClose={handleCloseVaccineDialog}
                vaccine={vaccine}
                setVaccine={setVaccine}
                handleReload={fetchVaccines}
                dialogType="update"
            />
        </>
    );
};

export default VaccineList;
