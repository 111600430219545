import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";

import { StyledInjection } from "./index.style";
import InjectionList from "./InjectionList";

const INITIAL_PAGING = { page: 1, total: 0 };

const Injection = () => {
    const [search, setSearch] = useState("");
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [reload, setReload] = useState(false);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    return (
        <StyledInjection>
            <div className="filter">
                <div className="search">
                    <TextField
                        value={search}
                        size="small"
                        fullWidth
                        placeholder={"Tìm kiếm theo Mã tiềm chủng/Họ tên/Tên người bảo hộ/Số điện thoại"}
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    {reload && <CircularProgress size={20} />}
                </Stack>
            </div>
            <InjectionList search={search} paging={paging} onChangePage={handleChangePaging} setReload={setReload} />
        </StyledInjection>
    );
};

export default Injection;
