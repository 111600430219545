import { COLOR } from "./color";

const SIDEBAR_WIDTH = 260;

const BOX_SHADOW = "0px 4px 24px rgba(0, 0, 0, 0.06)";

const TOOLTIP_BOX_SHADOW = "0px 4px 15px 0px #2C3F5859";

const PACKAGE_BOX_SHADOW = "0px 8px 32px rgba(254, 181, 52, 0.4)";

const BORDER_RADIUS = "5px";

const AVATAR_COLORS = [COLOR.primary, COLOR.success, COLOR.warning, COLOR.info];

const PACKAGE_HEIGHT_EXTRA = 32;

export {
    SIDEBAR_WIDTH,
    BOX_SHADOW,
    BORDER_RADIUS,
    AVATAR_COLORS,
    TOOLTIP_BOX_SHADOW,
    PACKAGE_BOX_SHADOW,
    PACKAGE_HEIGHT_EXTRA,
};
