import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const vaccineSlice = createSlice({
    name: "vaccine",
    initialState: initialState,
    reducers: {
        setVaccines: (state, action) => {
            return [...action.payload];
        },
    },
});

export const { setVaccines } = vaccineSlice.actions;

export default vaccineSlice.reducer;
