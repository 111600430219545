import React, { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";

import { StyledStore } from "./index.style";
import StoreList from "./StoreList";
import { INIT_STORE } from "../../constants/store";
import CreateStoreDialog from "./CreateStoreDialog";

const INITIAL_PAGING = { page: 1, total: 0 };

const Store = () => {
    const [search, setSearch] = useState("");
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [createStoreDialog, setCreateStoreDialog] = useState(false);
    const [store, setStore] = useState({});
    const [reload, setReload] = useState(false);

    const handleChangeSearch = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const handleChangePaging = ({ page, total }) => {
        setPaging({ page: page ?? 1, total: total ?? 0 });
    };

    const handleOpenCreateStoreDialog = () => {
        setStore(INIT_STORE);
        setCreateStoreDialog(true);
    };

    const handleCloseCreateStoreDialog = () => {
        setCreateStoreDialog(false);
    };

    const handleReload = () => {
        setReload((prev) => !prev);
    };

    return (
        <>
            <StyledStore>
                <div className="filter">
                    <div className="search">
                        <TextField
                            value={search}
                            size="small"
                            fullWidth
                            placeholder={"Tìm kiếm phòng tiêm theo tên, địa chỉ hoặc hotline"}
                            onChange={handleChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            color="success"
                            sx={{ paddingTop: "7px", paddingBottom: "6.5px", marginRight: "5px" }}
                            onClick={handleOpenCreateStoreDialog}
                        >
                            Thêm phòng tiêm
                        </Button>
                    </Stack>
                </div>
                <StoreList search={search} paging={paging} onChangePage={handleChangePaging} reload={reload} />
            </StyledStore>
            <CreateStoreDialog
                createStoreDialog={createStoreDialog}
                handleCloseCreateStoreDialog={handleCloseCreateStoreDialog}
                store={store}
                setStore={setStore}
                handleReload={handleReload}
                dialogType="create"
            />
        </>
    );
};

export default Store;
