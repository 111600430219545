import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";

import Dialog from "../../components/Dialog";
import { VACCINE_STATUS, INT_VACCINE } from "../../constants/vaccines";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { setVaccines } from "../../stores/vaccineSlice";
import { ALL_STORE } from "../../constants";

const INT_VACCINE_ERROR = {
    price: false,
    cost: false,
    amount: false,
    discount: false,
};

const VaccineDialog = ({ open, handleClose, vaccine, setVaccine, handleReload, dialogType }) => {
    const [vaccineError, setVaccineError] = useState(INT_VACCINE_ERROR);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const currentStore = useSelector((state) => state.store.currentStore);

    const dispatch = useDispatch();

    const getStore = async () => {
        if (currentStore !== ALL_STORE) {
            try {
                const { store } = await apis.store.getStore(currentStore);
                const vaccines = store.vaccines
                    .filter((vaccine) => vaccine.status === VACCINE_STATUS.ACTIVE)
                    .map(({ detail, ...vaccineInfo }) => {
                        delete detail.storeId;
                        delete detail.vaccineId;
                        return { ...detail, ...vaccineInfo };
                    });
                dispatch(setVaccines(vaccines));
            } catch (error) {
                handleCallApiError(error);
            }
        }
    };

    const handleChangeVaccine = (event, field) => {
        const value = event.target.value;
        setVaccine((prev) => ({ ...prev, [field]: value }));
        if (["price", "cost"].includes(field)) {
            const intValue = parseInt(value, 10);
            if (intValue < 0) setVaccineError((prev) => ({ ...prev, [field]: true }));
            else setVaccineError((prev) => ({ ...prev, [field]: false }));
        }
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.checked);
    };

    const handleCloseDialog = () => {
        handleClose();
        setVaccineError(INT_VACCINE_ERROR);
        setVaccine({});
    };

    const validate = () => {
        let isSuccess = true;
        let error = { ...vaccineError };
        Object.keys(INT_VACCINE).forEach((key) => {
            if (["price", "cost"].includes(key)) {
                const intValue = parseInt(key, 10);
                if (intValue < 0) {
                    error = { ...error, [key]: true };
                    isSuccess = false;
                }
            } else if (vaccine[key] === INT_VACCINE[key]) {
                error = { ...error, [key]: true };
                isSuccess = false;
            }
        });
        setVaccineError(error);
        return isSuccess;
    };

    const handleCreateVaccine = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            await apis.vaccine.createVaccine(vaccine);
            handleCloseDialog();
            toast.success("Thêm vắc xin thành công");
            handleReload();
            getStore();
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    const handleUpdateVaccine = async () => {
        if (!validate()) {
            toast.warn("Nhập đầy đủ thông tin");
            return;
        }
        setLoading(true);
        try {
            const res = await apis.vaccine.updateVaccine(vaccine.id, {
                ...vaccine,
                status: status ? VACCINE_STATUS.ACTIVE : VACCINE_STATUS.INACTIVE,
            });
            if (res.status === 1) {
                handleCloseDialog();
                toast.success("Cập nhật thông tin thành công");
                handleReload();
                getStore();
            } else {
                toast.error("Cập nhật thông tin thất bại");
            }
        } catch (error) {
            handleCallApiError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setStatus(vaccine?.status === VACCINE_STATUS.ACTIVE);
    }, [vaccine]);

    return (
        <Dialog
            title={dialogType === "create" ? "Thêm vắc xin" : "Cập nhật vắc xin"}
            maxWidth="md"
            open={open}
            onClose={handleCloseDialog}
        >
            <DialogContent>
                {vaccine && Object.keys(vaccine).length > 0 && (
                    <Box>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={5}>
                                <Typography fontWeight={500} mb={1}>
                                    Tên vắc xin:
                                </Typography>
                                <TextField
                                    error={vaccineError.name}
                                    value={vaccine.name}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "name")}
                                    placeholder="Tên vắc xin"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Typography fontWeight={500} mb={1}>
                                    Mô tả:
                                </Typography>
                                <TextField
                                    error={vaccineError.desc}
                                    value={vaccine.desc}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "desc")}
                                    placeholder="Mô tả"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={4}>
                                <Typography fontWeight={500} mb={1}>
                                    Xuất xứ:
                                </Typography>
                                <TextField
                                    error={vaccineError.origin}
                                    value={vaccine.origin}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "origin")}
                                    placeholder="Xuất xứ"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography fontWeight={500} mb={1}>
                                    Giá niêm yết:
                                </Typography>
                                <TextField
                                    error={vaccineError.price}
                                    value={vaccine.price}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "price")}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography fontWeight={500} mb={1}>
                                    Giá nhập vào:
                                </Typography>
                                <TextField
                                    error={vaccineError.cost}
                                    value={vaccine.cost}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "cost")}
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mb={2}>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Liều lượng:
                                </Typography>
                                <TextField
                                    error={vaccineError.dosage}
                                    value={vaccine.dosage}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "dosage")}
                                    placeholder="1 ml, 2 ml, 3 ml"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight={500} mb={1}>
                                    Hình thức tiêm:
                                </Typography>
                                <TextField
                                    error={vaccineError.method}
                                    value={vaccine.method}
                                    size="small"
                                    fullWidth
                                    onChange={(event) => handleChangeVaccine(event, "method")}
                                    placeholder="Hình thức tiêm"
                                />
                            </Grid>
                        </Grid>
                        {dialogType === "update" && (
                            <Stack mb={3} direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Typography fontWeight={500}>Trạng thái:</Typography>
                                <FormControlLabel
                                    control={<Switch checked={status} onChange={handleChangeStatus} />}
                                    label={status ? "Sử dụng" : "Không sử dụng"}
                                />
                            </Stack>
                        )}
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                    Hủy
                </Button>
                {dialogType === "create" ? (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleCreateVaccine}>
                        Thêm mới
                    </LoadingButton>
                ) : (
                    <LoadingButton variant="contained" color="success" loading={loading} onClick={handleUpdateVaccine}>
                        Lưu thay đổi
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default VaccineDialog;
