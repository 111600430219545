const ROUTE = {
    DASHBOARD: "/",
    LOGIN: "/login",
    RECEPTIONIST: "/receptionist",
    DOCTOR: "/doctor",
    CASHIER: "/cashier",
    INJECTION: "/injection",
    STORE: "/store",
    VACCINE: "/vaccine",
    WAREHOUSE: "/warehouse",
    USER: "/user",
    ROLE: "/role",
    PERMISSION: "/permission",
};

export default ROUTE;
